import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import 'jquery';
import { ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { BoService } from '../services/bo.service';
declare var $: any;

@Component({
  selector: 'app-nouveau-compte',
  templateUrl: './nouveau-compte.component.html',
  styleUrls: ['./nouveau-compte.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NouveauCompteComponent implements OnInit {

  constructor(private authService: AuthService, private boService: BoService, private router: Router,private ngZone:NgZone) { }

  navigateTo(url){
    this.router.navigate([url]);
  }

  ngOnInit() {
  let t = this;
    $(document).ready(function(){
      $('.menubo,.headerbo').css({'display':'none'});
      $('body').css({'overflow':'hidden'});
      $('.blocaddevent').fadeOut(0).css({'margin-top':'40px'});
      $('.contblocae').css({'height':'670px'});
      $('.inncmail').keyup(function(){
        if($(this).val()!=''){
          $('.inncmail').removeClass('signalinvide').attr('placeholder','sample@sample.com');
        };
      });
      $('.inncpassword').keyup(function(){
        if($(this).val()!=''){
          $('.inncpassword').removeClass('signalinvide').attr('placeholder','6 caractères minimum');
        };
      });
      $('.inncpasswordconf').keyup(function(){
        if($(this).val()!=''){
          $('.inncpasswordconf').removeClass('signalinvide').attr('placeholder','Confirmez la saisie');
        };
      });
      $(document).on('keypress',function(e) {
        if(e.which == 13){$('.btncreate').trigger('click');};
      });
      var re=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var checlic=0;
      $('.btncreate').click(function(){
      if(checlic==0){ 
        checlic=1;
        $('.texterrorlog').html('');
        var getmail=$('.inncmail').val();
        var getpass=$('.inncpassword').val();
        var getpassconf=$('.inncpasswordconf').val();
        var checkinv=0;
        if(getmail==''){$('.inncmail').addClass('signalinvide').attr('placeholder','Ce champ est requis');checkinv=1;checlic=0;};
        if(getpass==''){$('.inncpassword').addClass('signalinvide').attr('placeholder','Ce champ est requis');checkinv=1;checlic=0;};
        if(getpassconf==''){$('.inncpasswordconf').addClass('signalinvide').attr('placeholder','Ce champ est requis');checkinv=1;checlic=0;};
        if(getpass!=getpassconf){$('.inncpasswordconf').addClass('signalinvide');$('.texterrorlog').html('<i class="far fa-exclamation-triangle"></i> &nbsp;La confirmation mot de passe ne correspond pas');checkinv=1;checlic=0;};
        if(getpass.length<6){$('.inncpassword').addClass('signalinvide');$('.texterrorlog').html('Votre mot de passe doit comprendre au moins 6 caractères');checkinv=1;checlic=0;};
        if(!getmail.match(re)){$('.inncmail').addClass('signalinvide');$('.texterrorlog').html('<i class="far fa-exclamation-triangle"></i> &nbsp;Veuillez indiquer une adresse e-mail valide');checkinv=1;checlic=0;};
        if(checkinv==0){
          $(this).html('Chargement...');
          /* t.authService.createAccount(getmail,getpass).then((data) => {
            t.authService.signIn(getmail,getpass).then((data) => {
              localStorage.setItem('authUser','true');
              localStorage.setItem('dataUser',JSON.stringify(data));
                t.boService.getInfoUser().then((data) => {
                $('.blocnc').fadeOut(400);
                $('.headae').fadeOut(0);
                $('.fdnc').css({'background-position':'99%'});
                $('.titfirstev').html('<i class="fad fa-calendar-star"></i> &nbsp;Créez votre premier événement').css({'color':'#ffffff'});
                $('.blocaddevent').fadeIn(400);
              }).catch(error => {
                console.log('Erreur de chargement des infos user ( '+error+' )');
                checlic=0;
                $(this).html('Se connecter &nbsp;<i class="fas fa-sign-in-alt"></i>');
              });
            }).catch(error => {
              console.log('Erreur de login ( '+error.error.message+' )');
              checlic=0;
              $(this).html('<i class="far fa-user-circle"></i> &nbsp;Créer un compte');
            });
          }).catch(error => {
            if(error.error.message=='Ce compte est déjà présent.'){
              $('.texterrorlog').html('<i class="far fa-exclamation-triangle"></i> &nbsp;Cet identifiant est déja utilisé');
              checlic=0;
              $(this).html('<i class="far fa-user-circle"></i> &nbsp;Créer un compte');
            }else{
              console.log('Erreur de création de compte ( '+error.error.message+' )');
              checlic=0;
              $(this).html('<i class="far fa-user-circle"></i> &nbsp;Créer un compte');
            };
          }); */
        };
      };
    });
  });
  }
}