import { Component, OnInit } from '@angular/core';
import 'jquery';
import { ViewEncapsulation } from '@angular/core';
import { BoService } from '../services/bo.service';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';
declare var $: any;
import * as Chartist from 'chartist';

@Component({
  selector: 'app-tableau-de-bord',
  templateUrl: './tableau-de-bord.component.html',
  styleUrls: ['./tableau-de-bord.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TableauDeBordComponent implements OnInit {

  constructor(private boService: BoService, private router: Router, private ngZone:NgZone) { }

  ngOnInit() {
    let t=this;
    $(document).ready(function(){
      // --- Recuperation et création des data utiles pour jq : ------------------
      var dataList;
      var idActif='';
      var TDBdemo=0;
      var g0;
      var g=[];
      var toTick=0;
      var toTickTxt='';
      var toScan=0;
      var toScanTxt='';
      var totS=0;
      var totSTxt='';
      var totSPer='0';
      var totIn=0;
      var totInTxt='';
      var totInPer='0';
      var totOut=0;
      var totOutTxt='';
      var totOutPer='0';
      var dlg=0;
      var tbS=[];
      var tbSL=0;
      var tbgSM=[];
      var tbgSH=[];
      var tbgSD=[];
      var tbIn=[];
      var tbInL=0;
      var tbgInM=[];
      var tbgInH=[];
      var tbgInD=[];
      var tbOut=[];
      var tbOutL=0;
      var tbgOutM=[];
      var tbgOutH=[];
      var tbgOutD=[];
      var tbAccess=[];
      var nbAccess=0;
      var sortAccess=1;
      var sortTpl=1;
      var ldGM=0;
      var ldGH=0;
      var ldGD=0;
      var def=35;
      var wicgt=0;
      var wiG=0;
      var newsize=0;
      var lastsize=0;
      var tox=0
      var defsize=0;
      var vi=def;
      var mt=def;
      var zooMod='M';
      var chartD;
      var chartE;
      var dataBas;
      var newDate=new Date();
      var year=newDate.getFullYear();
      var month=(newDate.getMonth())+1;
      var countM=1;
      var countH=1;
      var countD=1;
      var maxCountM=[1,1,1];
      var maxCountH=[1,1,1];
      var maxCountD=[1,1,1];
      var checkMax=1;
      var overmax=[0,0,0];
      var maxM=2880; // < prio 3    // 2 jours (min 38h)
      var checkMaxH=1;
      var maxH=2400; // < prio 2    // 100 jours (min 13 jours)
      var checkMaxD=1;
      var maxD=1825; // < prio 1    // 5 ans
      var c0;
      var typGraph='In';
      var lvi = 0;
      //--------------------------------------------------------------------------
      // --- Initialisation globale : --------------------------------------------
      var wiw=$(window).width();
      $('.menubo,.headerbo').css({'display':'block'});
      $('#htitsec').html('Accueil');
      $('#htitsec2').html('Tableau de bord');
      $('.menubs').attr('id','');$('.menubs-1').attr('id','actif1');
      $('.secmenu2bs').attr('id','');$('.1s1').attr('id','actif2');
      if(wiw>820){
        $('body').css({'overflow':'hidden'});
      }else{
        $('body').css({'overflow':'auto'});
      };
      $(window).scrollTop(0);
      $('.bottomae').css({'top':'500px'});
      $('.blocaddevent').css({'height':'500px','margin-top':'auto'});$('.contblocae');
      //--------------------------------------------------------------------------
      // --- Initialisation contenu : --------------------------------------------
      // LOAD PARTIE 1 (local) :
      dataList=JSON.parse(localStorage.getItem('dataList'));
      idActif=dataList._id;
      dataBas=t.boService.bas;
      var namv=dataList.name;
      var datv=dataList.dateEndValide;
      var tplScan=dataList.tpl;
      var nbtplScan=tplScan.length;
      var stepconf=[1,1,0,0,0];
      // Génération :
      if(namv.length>25){namv=namv.substring(0,23);namv+='...'};
      $('.titev').html(namv);
      $('.namelist').html(namv);
      // ___________
      var dateevmes=datv.split(' ');
      var dateevme=dateevmes[0].replace(/-/g,'/');
      var hvm=dateevmes[1].split(':');
      var hourevme=hvm[0]+'h'+hvm[1];
      $('.datev').html('<i class="fa-regular fa-calendar-clock"></i> &nbsp;<span class="respdis3"> Valide jusqu\'au </span><span class="respvis3">Expire le </span>'+dateevme+' <span class="respdis10">à </span><span class="respdis11">'+hourevme+'</span>');
      // ___________
      $('.adressev').html('<i class="fa-regular fa-arrow-right-to-arc"></i> &nbsp;accès<span class="respdis30"> associé</span><span class="respdis20"> à cette liste</span>');
      // ___________
      $('#mtntpltocheck').html(nbtplScan);
      if(nbtplScan>1){
        $('.txttbs').html('tarifs');
      }else{
        $('.txttbs').html('tarif');
      };
      $('.bas1').html(dataBas[0]);
      $('.bas2').html(dataBas[1]);
      $('.bas3').html(dataBas[2]);
      $('.bas4').html(dataBas[3]);
      $('.bas5').html(dataBas[4]);
      // ___________
      var darkMode='off';
      var contrastMode='off';
      if(JSON.parse(localStorage.getItem('infoUser')).darkMode=='on'){darkMode='on'};
      if(JSON.parse(localStorage.getItem('infoUser')).contrastMode=='on'){contrastMode='on'};
      // LOAD PARTIE 2 (SERVICES) :
      if(TDBdemo==1){
        // à faire
      }else{
        getDataTB();
      };
      async function getDataTB(){
        // prend en compte que les scan des tpl présent à l'instant T dans la liste
        for(var i=0;i<tplScan.length;i++){
          tplScan[i]['toScan']=0;
          tplScan[i]['totalS']=0;
          tplScan[i]['totalIn']=0;
          tplScan[i]['totalOut']=0;
        };
        t.boService.getAllKeys().then(data => {
          var dk;
          dk=data;
          dk.push({id:'fromBo',value:{_id:'fromBo',name: 'Backoffice',enableList: []}});
          dk.push({id:'delAccess',value:{_id:'delAccess',name: 'Accès supprimés',enableList: []}});
          for(var i=0;i<dk.length;i++){
            dk[i]=dk[i].value;
            if(!dk[i].enableList){
              dk[i].enableList=[];
            };
            dk[i]['toScan']=0;
            dk[i]['totalS']=0;
            dk[i]['totalIn']=0;
            dk[i]['totalOut']=0;
          };
          t.boService.getAllParticipants().then(data => {
            g0=data;
            if(g0.length>0){
              for(var i=0;i<g0.length;i++){
                g=g.concat(g0[i]);
              };
              if(g.length>0){
                for(var i=0;i<g.length;i++){
                  g[i] = g[i];
                  if(!g[i].firstNameUser){g[i].firstNameUser=""};
                  if(!g[i].lastNameUser){g[i].lastNameUser=""};
                 if(g[i].canal=='stock' && g[i].firstNameUser.substr(0,7)=='prenom-' && g[i].lastNameUser.substr(0,4)=='nom-'){
                    g[i].firstAndLastName='Impression de stock';
                  }else if(g[i].canal=='guichet' && g[i].firstNameUser=='impression' && g[i].lastNameUser.substr(0,9)=='sur place'){
                    g[i].firstAndLastName='Vente sur place';
                  }else if(g[i].canal=='Invited' && g[i].firstNameUser=='gratuite' && g[i].lastNameUser=='Invitation'){
                    g[i].firstAndLastName='Invitation';
                  }else{
                    var firstName=g[i].firstNameUser.trim();
                    var lastName=g[i].lastNameUser.trim();
                    g[i].firstAndLastName=lastName.substr(0,1).toUpperCase()+lastName.substr(1).toLowerCase()+' '+firstName.substr(0,1).toUpperCase()+firstName.substr(1).toLowerCase();
                  };
                  /* var ci='';
                  if(g[i].canal=='stock'){
                    ci='<i class="fa-duotone fa-layer-group"></i>';
                  }else if(g[i].canal=='guichet'){
                    ci='<i class="fa-duotone fa-cash-register"></i>';
                  }else if(g[i].canal=='Invited'){
                    ci='<i class="fa-duotone fa-envelope-open-text"></i>';
                  }else if(g[i].canal=='Wilout'){
                    ci='<i class="fa-duotone fa-browser"></i>';
                  }else{
                    ci='<i class="fa-duotone fa-download"></i>';
                  };
                  g[i].canalIc=ci; */
                  g[i]['tplName']='Inconnu';
                  for(var u=0;tplScan.length;u++){
                    if(g[i].idTplTicket==tplScan[u].id){
                      toTick++;
                      if(tplScan[u].infinity==true || tplScan[u].infinity=='true'){
                        toScan+=1;
                        tplScan[u].toScan+=1;
                      }else{
                        toScan+=tplScan[u].scanNbr;
                        tplScan[u].toScan+=tplScan[u].scanNbr;
                      };
                      g[i].tplName=tplScan[u].name;
                      if(g[i].scans){
                        if(g[i].scans[idActif]){
                          if(g[i].scans[idActif].dateScan){
                            tplScan[u].totalS+=g[i].scans[idActif].dateScan.length;
                            tplScan[u].totalIn+=g[i].scans[idActif].dateScan.length;
                          };
                          if(g[i].scans[idActif].dateDescan){
                            tplScan[u].totalS-=g[i].scans[idActif].dateDescan.length;
                            tplScan[u].totalOut+=g[i].scans[idActif].dateDescan.length;
                          };
                        };
                      };
                      break;
                    };
                  };
                  if(g[i].scans){
                    if(g[i].scans[idActif]){
                      if(g[i].scans[idActif].dateScan){
                        for(var a=0;a<g[i].scans[idActif].dateScan.length;a++){
                          totS++;
                          totIn++;
                          var dfpIn={};
                          var d0=g[i].scans[idActif].dateScan[a].date.split(' ');
                          var d1=d0[0].split('-');
                          if(d1[1].length<2){d1[1]='0'+d1[1]};
                          if(d1[0].length<2){d1[0]='0'+d1[0]};
                          var dYear=d1[2];
                          var dMonth=d1[1];
                          var dDay=d1[0];
                          var d1=d1[2]+d1[1]+d1[0];
                          var d2=d0[1].split(':');
                          if(d2[2].length<2){d2[2]='0'+d2[2]};
                          if(d2[1].length<2){d2[1]='0'+d2[1]};
                          if(d2[0].length<2){d2[0]='0'+d2[0]};
                          var dHour=d2[0];
                          var dMinute=d2[1];
                          var dSecond=d2[2];
                          var d2=d2[0]+d2[1]+d2[2];
                          var d3=parseFloat(d1+d2);
                          dfpIn['dateSort']=d3;
                          dfpIn['dateTxtM']=dDay+'/'+dMonth+' à '+dHour+'h'+dMinute;
                          dfpIn['dateTxtH']=dDay+'/'+dMonth+' à '+dHour+'h';
                          dfpIn['dateTxtD']=dDay+'/'+dMonth;
                          dfpIn['dYear']=parseFloat(dYear);
                          dfpIn['dMonth']=parseFloat(dMonth);
                          dfpIn['dDay']=parseFloat(dDay);
                          dfpIn['dHour']=parseFloat(dHour);
                          dfpIn['dMinute']=parseFloat(dMinute);
                          dfpIn['dSecond']=parseFloat(dSecond);
                          dfpIn['dcD']=parseFloat(dYear+dMonth+dDay);
                          dfpIn['dcH']=parseFloat(dYear+dMonth+dDay+dHour);
                          dfpIn['dcM']=parseFloat(dYear+dMonth+dDay+dHour+dMinute);
                          dfpIn['date']=g[i].scans[idActif].dateScan[a].date;
                          dfpIn['prenom']=g[i].firstNameUser;
                          dfpIn['nom']=g[i].lastNameUser;
                          dfpIn['nomEtPrenom']=g[i].firstAndLastName;
                          dfpIn['email']=g[i].email;
                          dfpIn['type']='in';
                          dfpIn['tplName']=g[i].tplName;
                          dfpIn['tplId']=g[i].idTplTicket;
                          dfpIn['accessName']='Inconnu';
                          dfpIn['accessId']=g[i].scans[idActif].dateScan[a].idStaff;
                          var findAccess=0;
                          for(var u=0;u<dk.length;u++){
                            if(g[i].scans[idActif].dateScan[a].idStaff==dk[u]._id){
                              findAccess=1;
                              dfpIn['accessName']=dk[u].name;
                              dk[u].totalS++;
                              dk[u].totalIn++;
                              break;
                            };
                          };
                          if(findAccess==0){
                            dfpIn['accessName']='Accès supprimé';
                            dk[dk.length-1].totalS++;
                            dk[dk.length-1].totalIn++;
                          };
                          tbS.push(dfpIn);
                          tbIn.push(dfpIn);
                        };
                      };
                      if(g[i].scans[idActif].dateDescan){
                        for(var a=0;a<g[i].scans[idActif].dateDescan.length;a++){
                          totS--;
                          totOut++;
                          var dfpOut={};
                          var d0=g[i].scans[idActif].dateDescan[a].date.split(' ');
                          var d1=d0[0].split('-');
                          if(d1[1].length<2){d1[1]='0'+d1[1]};
                          if(d1[0].length<2){d1[0]='0'+d1[0]};
                          var dYear=d1[2];
                          var dMonth=d1[1];
                          var dDay=d1[0];
                          var d1=d1[2]+d1[1]+d1[0];
                          var d2=d0[1].split(':');
                          if(d2[2].length<2){d2[2]='0'+d2[2]};
                          if(d2[1].length<2){d2[1]='0'+d2[1]};
                          if(d2[0].length<2){d2[0]='0'+d2[0]};
                          var dHour=d2[0];
                          var dMinute=d2[1];
                          var dSecond=d2[2];
                          var d2=d2[0]+d2[1]+d2[2];
                          var d3=parseFloat(d1+d2);
                          dfpOut['dateSort']=d3;
                          dfpOut['dateTxtM']=dDay+'/'+dMonth+' à '+dHour+'h'+dMinute;
                          dfpOut['dateTxtH']=dDay+'/'+dMonth+' à '+dHour+'h';
                          dfpOut['dateTxtD']=dDay+'/'+dMonth;
                          dfpOut['dYear']=parseFloat(dYear);
                          dfpOut['dMonth']=parseFloat(dMonth);
                          dfpOut['dDay']=parseFloat(dDay);
                          dfpOut['dHour']=parseFloat(dHour);
                          dfpOut['dMinute']=parseFloat(dMinute);
                          dfpOut['dSecond']=parseFloat(dSecond);
                          dfpOut['dcD']=parseFloat(dYear+dMonth+dDay);
                          dfpOut['dcH']=parseFloat(dYear+dMonth+dDay+dHour);
                          dfpOut['dcM']=parseFloat(dYear+dMonth+dDay+dHour+dMinute);
                          dfpOut['date']=g[i].scans[idActif].dateDescan[a].date;
                          dfpOut['prenom']=g[i].firstNameUser;
                          dfpOut['nom']=g[i].lastNameUser;
                          dfpIn['nomEtPrenom']=g[i].firstAndLastName;
                          dfpOut['email']=g[i].email;
                          dfpOut['type']='out';
                          dfpOut['tplName']=g[i].tplName;
                          dfpOut['tplId']=g[i].idTplTicket;
                          dfpOut['accessName']='Inconnu';
                          dfpOut['accessId']=g[i].scans[idActif].dateDescan[a].idStaff;
                          var findAccess=0;
                          for(var u=0;u<dk.length;u++){
                            if(g[i].scans[idActif].dateDescan[a].idStaff==dk[u]._id){
                              findAccess=1;
                              dfpOut['accessName']=dk[u].name;
                              dk[u].totalS--;
                              dk[u].totalOut++;
                              break;
                            };
                          };
                          if(findAccess==0){
                            dfpOut['accessName']='Accès supprimé';
                            dk[dk.length-1].totalS--;
                            dk[dk.length-1].totalOut++;
                          };
                          tbS.push(dfpOut);
                          tbOut.push(dfpOut);
                        };
                      };
                    };
                  };
                };
              };
            };
            toTickTxt=toTick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            toScanTxt=toScan.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            totSTxt=totS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            totInTxt=totIn.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            totOutTxt=totOut.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            if(toScan==0){
              totSPer='0';
              totInPer='0';
            }else{
              totSPer=((totS/toScan)*100).toFixed(0);
              totInPer=((totIn/toScan)*100).toFixed(0);
            };
            if(totIn==0){
              totOutPer='0';
            }else{
              totOutPer=((totOut/totIn)*100).toFixed(0);
            };
            for(var i=0;i<dk.length;i++){
              var idfind=0;
              for(var u=0;u<dk[i].enableList.length;u++){
                if(dk[i].enableList[u]==idActif){
                  idfind=1;
                  nbAccess++;
                  tbAccess.push(dk[i]);
                  break;
                };
              };
              if(idfind==0 && dk[i].totalIn>0){
                tbAccess.push(dk[i]);
              };
            };
            tbSL=tbS.length;
            if(tbSL>0){
              tbS=tbS.sort(function(a,b){return (a.dateSort)-(b.dateSort)});
              countM=1;
              countH=1;
              countD = 1;
              checkMax=1;
              for(var i=1;i<tbSL;i++){
                var type=tbS[i].type;
                if(type=='in'){
                  type=1;
                }else{
                  type=0;
                };
                if(checkMaxD<=maxD && tbS[i].dcD==tbS[i-1].dcD){
                  if(type==1){
                    countD++;
                  }else{
                    countD--;
                  };
                  if(countD>maxCountD[0]){
                    maxCountD[0]=countD;
                  };
                  if(checkMaxH<=maxH && tbS[i].dcH==tbS[i-1].dcH){
                    if(type==1){
                      countH++;
                    }else{
                      countH--;
                    };
                    if(countH>maxCountH[0]){
                      maxCountH[0]=countH;
                    };
                    if(checkMax<=maxM && tbS[i].dcM==tbS[i-1].dcM){
                      if(type==1){
                        countM++;
                      }else{
                        countM--;
                      };
                      if(countM>maxCountM[0]){
                        maxCountM[0]=countM;
                      };
                    }else if(checkMax<=maxM){
                      tbgSM.push({'count':countM,'date':tbS[i-1].dateTxtM});checkMax++;
                      c0=cnt0(tbS[i],tbS[i-1],'m',checkMax);
                      checkMax=checkMax+(c0.length-1);
                      for(var a=1;a<c0.length;a++){
                        tbgSM.push({'count':countM,'date':c0[a]});
                      };
                      if(type==1){
                        countM++;
                      }else{
                        countM--;
                      };
                    };
                  }else if(checkMaxH<=maxH){
                    if(checkMax<=maxM){
                      tbgSM.push({'count':countM,'date':tbS[i-1].dateTxtM});checkMax++;
                      c0=cnt0(tbS[i],tbS[i-1],'m',checkMax);
                      checkMax=checkMax+(c0.length-1);
                      for(var a=1;a<c0.length;a++){
                        tbgSM.push({'count':countM,'date':c0[a]});
                      };
                      if(type==1){
                        countM++;
                      }else{
                        countM--;
                      };
                    };
                    if(checkMaxH<=maxH){
                      tbgSH.push({'count':countH,'date':tbS[i-1].dateTxtH});checkMaxH++;
                      c0=cnt0(tbS[i],tbS[i-1],'h',checkMaxH);
                      checkMaxH=checkMaxH+(c0.length-1);
                      for(var a=1;a<c0.length;a++){
                        tbgSH.push({'count':countH,'date':c0[a]});
                      };
                      if(type==1){
                        countH++;
                      }else{
                        countH--;
                      };
                    };
                  };
                }else if(checkMaxD<=maxD){
                  if(checkMax<=maxM){
                    tbgSM.push({'count':countM,'date':tbS[i-1].dateTxtM});checkMax++;
                    c0=cnt0(tbS[i],tbS[i-1],'m',checkMax);
                    checkMax=checkMax+(c0.length-1);
                    for(var a=1;a<c0.length;a++){
                      tbgSM.push({'count':countM,'date':c0[a]});
                    };
                    if(type==1){
                      countM++;
                    }else{
                      countM--;
                    };
                  };
                  if(checkMaxH<=maxH){
                    tbgSH.push({'count':countH,'date':tbS[i-1].dateTxtH});checkMaxH++;
                    c0=cnt0(tbS[i],tbS[i-1],'h',checkMaxH);
                    checkMaxH=checkMaxH+(c0.length-1);
                    for(var a=1;a<c0.length;a++){
                      tbgSH.push({'count':countH,'date':c0[a]});
                    };
                    if(type==1){
                      countH++;
                    }else{
                      countH--;
                    };
                  };
                  if(checkMaxD<=maxD){
                    tbgSD.push({'count':countD,'date':tbS[i-1].dateTxtD});checkMaxD++;
                    c0=cnt0(tbS[i],tbS[i-1],'d',checkMaxD);
                    checkMaxD=checkMaxD+(c0.length-1);
                    for(var a=1;a<c0.length;a++){
                      tbgSD.push({'count':countD,'date':c0[a]});
                    };
                    if(type==1){
                      countD++;
                    }else{
                      countD--;
                    };
                  }
                  ;
                };
              };
              if(checkMaxD<=maxD){
                tbgSD.push({'count':countD,'date':tbS[i-1].dateTxtD});
                if(checkMaxH<=maxH){
                  tbgSH.push({'count':countH,'date':tbS[i-1].dateTxtH});
                  if(checkMax<=maxM){
                    tbgSM.push({'count':countM,'date':tbS[i-1].dateTxtM});
                  }else{
                    overmax[0]=1;
                  };
                }else{
                  overmax[0]=2;
                };
              }else{
                overmax[0]=3;
              };
            };
            tbInL=tbIn.length;
            if(tbInL>0){
              tbIn=tbIn.sort(function(a,b){return (a.dateSort)-(b.dateSort)});
              countM=1;
              countH=1;
              countD = 1;
              checkMax=1;
              for(var i=1;i<tbInL;i++){
                if(checkMaxD<=maxD && tbIn[i].dcD==tbIn[i-1].dcD){
                  countD++;
                  if(countD>maxCountD[1]){
                    maxCountD[1]=countD;
                  };
                  if(checkMaxH<=maxH && tbIn[i].dcH==tbIn[i-1].dcH){
                    countH++;
                    if(countH>maxCountH[1]){
                      maxCountH[1]=countH;
                    };
                    if(checkMax<=maxM && tbIn[i].dcM==tbIn[i-1].dcM){
                      countM++;
                      if(countM>maxCountM[1]){
                        maxCountM[1]=countM;
                      };
                    }else if(checkMax<=maxM){
                      tbgInM.push({'count':countM,'date':tbIn[i-1].dateTxtM});checkMax++;countM=1;
                      c0=cnt0(tbIn[i],tbIn[i-1],'m',checkMax);
                      checkMax=checkMax+(c0.length-1);
                      for(var a=1;a<c0.length;a++){
                        tbgInM.push({'count':0,'date':c0[a]});
                      };
                    };
                  }else if(checkMaxH<=maxH){
                    if(checkMax<=maxM){
                      tbgInM.push({'count':countM,'date':tbIn[i-1].dateTxtM});checkMax++;countM=1;
                      c0=cnt0(tbIn[i],tbIn[i-1],'m',checkMax);
                      checkMax=checkMax+(c0.length-1);
                      for(var a=1;a<c0.length;a++){
                        tbgInM.push({'count':0,'date':c0[a]});
                      };
                    };
                    if(checkMaxH<=maxH){
                      tbgInH.push({'count':countH,'date':tbIn[i-1].dateTxtH});checkMaxH++;countH=1;
                      c0=cnt0(tbIn[i],tbIn[i-1],'h',checkMaxH);
                      checkMaxH=checkMaxH+(c0.length-1);
                      for(var a=1;a<c0.length;a++){
                        tbgInH.push({'count':0,'date':c0[a]});
                      };
                    };
                  };
                }else if(checkMaxD<=maxD){
                  if(checkMax<=maxM){
                    tbgInM.push({'count':countM,'date':tbIn[i-1].dateTxtM});checkMax++;countM=1;
                    c0=cnt0(tbIn[i],tbIn[i-1],'m',checkMax);
                    checkMax=checkMax+(c0.length-1);
                    for(var a=1;a<c0.length;a++){
                      tbgInM.push({'count':0,'date':c0[a]});
                    };
                  };
                  if(checkMaxH<=maxH){
                    tbgInH.push({'count':countH,'date':tbIn[i-1].dateTxtH});checkMaxH++;countH=1;
                    c0=cnt0(tbIn[i],tbIn[i-1],'h',checkMaxH);
                    checkMaxH=checkMaxH+(c0.length-1);
                    for(var a=1;a<c0.length;a++){
                      tbgInH.push({'count':0,'date':c0[a]});
                    };
                  };
                  if(checkMaxD<=maxD){
                    tbgInD.push({'count':countD,'date':tbIn[i-1].dateTxtD});checkMaxD++;countD=1;
                    c0=cnt0(tbIn[i],tbIn[i-1],'d',checkMaxD);
                    checkMaxD=checkMaxD+(c0.length-1);
                    for(var a=1;a<c0.length;a++){
                      tbgInD.push({'count':0,'date':c0[a]});
                    };
                  }
                  ;
                };
              };
              if(checkMaxD<=maxD){
                tbgInD.push({'count':countD,'date':tbIn[i-1].dateTxtD});
                if(checkMaxH<=maxH){
                  tbgInH.push({'count':countH,'date':tbIn[i-1].dateTxtH});
                  if(checkMax<=maxM){
                    tbgInM.push({'count':countM,'date':tbIn[i-1].dateTxtM});
                  }else{
                    overmax[1]=1;
                  };
                }else{
                  overmax[1]=2;
                };
              }else{
                overmax[1]=3;
              };
            };
            tbOutL=tbOut.length;
            if(tbOutL>0){
              tbOut=tbOut.sort(function(a,b){return (a.dateSort)-(b.dateSort)});
              countM=1;
              countH=1;
              countD = 1;
              checkMax=1;
              for(var i=1;i<tbOutL;i++){
                if(checkMaxD<=maxD && tbOut[i].dcD==tbOut[i-1].dcD){
                  countD++;
                  if(countD>maxCountD[2]){
                    maxCountD[2]=countD;
                  };
                  if(checkMaxH<=maxH && tbOut[i].dcH==tbOut[i-1].dcH){
                    countH++;
                    if(countH>maxCountH[2]){
                      maxCountH[2]=countH;
                    };
                    if(checkMax<=maxM && tbOut[i].dcM==tbOut[i-1].dcM){
                      countM++;
                      if(countM>maxCountM[2]){
                        maxCountM[2]=countM;
                      };
                    }else if(checkMax<=maxM){
                      tbgOutM.push({'count':countM,'date':tbOut[i-1].dateTxtM});checkMax++;countM=1;
                      c0=cnt0(tbOut[i],tbOut[i-1],'m',checkMax);
                      checkMax=checkMax+(c0.length-1);
                      for(var a=1;a<c0.length;a++){
                        tbgOutM.push({'count':0,'date':c0[a]});
                      };
                    };
                  }else if(checkMaxH<=maxH){
                    if(checkMax<=maxM){
                      tbgOutM.push({'count':countM,'date':tbOut[i-1].dateTxtM});checkMax++;countM=1;
                      c0=cnt0(tbOut[i],tbOut[i-1],'m',checkMax);
                      checkMax=checkMax+(c0.length-1);
                      for(var a=1;a<c0.length;a++){
                        tbgOutM.push({'count':0,'date':c0[a]});
                      };
                    };
                    if(checkMaxH<=maxH){
                      tbgOutH.push({'count':countH,'date':tbOut[i-1].dateTxtH});checkMaxH++;countH=1;
                      c0=cnt0(tbOut[i],tbOut[i-1],'h',checkMaxH);
                      checkMaxH=checkMaxH+(c0.length-1);
                      for(var a=1;a<c0.length;a++){
                        tbgOutH.push({'count':0,'date':c0[a]});
                      };
                    };
                  };
                }else if(checkMaxD<=maxD){
                  if(checkMax<=maxM){
                    tbgOutM.push({'count':countM,'date':tbOut[i-1].dateTxtM});checkMax++;countM=1;
                    c0=cnt0(tbOut[i],tbOut[i-1],'m',checkMax);
                    checkMax=checkMax+(c0.length-1);
                    for(var a=1;a<c0.length;a++){
                      tbgOutM.push({'count':0,'date':c0[a]});
                    };
                  };
                  if(checkMaxH<=maxH){
                    tbgOutH.push({'count':countH,'date':tbOut[i-1].dateTxtH});checkMaxH++;countH=1;
                    c0=cnt0(tbOut[i],tbOut[i-1],'h',checkMaxH);
                    checkMaxH=checkMaxH+(c0.length-1);
                    for(var a=1;a<c0.length;a++){
                      tbgOutH.push({'count':0,'date':c0[a]});
                    };
                  };
                  if(checkMaxD<=maxD){
                    tbgOutD.push({'count':countD,'date':tbOut[i-1].dateTxtD});checkMaxD++;countD=1;
                    c0=cnt0(tbOut[i],tbOut[i-1],'d',checkMaxD);
                    checkMaxD=checkMaxD+(c0.length-1);
                    for(var a=1;a<c0.length;a++){
                      tbgOutD.push({'count':0,'date':c0[a]});
                    };
                  }
                  ;
                };
              };
              if(checkMaxD<=maxD){
                tbgOutD.push({'count':countD,'date':tbOut[i-1].dateTxtD});
                if(checkMaxH<=maxH){
                  tbgOutH.push({'count':countH,'date':tbOut[i-1].dateTxtH});
                  if(checkMax<=maxM){
                    tbgOutM.push({'count':countM,'date':tbOut[i-1].dateTxtM});
                  }else{
                    overmax[2]=1;
                  };
                }else{
                  overmax[2]=2;
                };
              }else{
                overmax[2]=3;
              };
            };
            // ___________
            if(nbtplScan>0){
              stepconf[2]=1;
            };
            if((dk.length-2)>0){
              stepconf[3]=1;
            };
            if(nbAccess>0){
              stepconf[4]=1;
            };
            var cstep=0;
            for(var i=0;i<stepconf.length;i++){
              if(stepconf[i]==1){
                $('.stepT:eq('+i+')').addClass('stepon').children('i').attr('class','far fa-check-circle');
                cstep++;
              }else{
                $('.stepT:eq('+i+')').addClass('stepoff').children('i').attr('class','far  fa-circle');
              };
            };
            cstep=cstep*20;
            $('.mtprogstep').html(''+cstep+'%');
            $('.prog').css({'width':''+cstep+'%'});
            // ___________
            // B1
            if(toTick>1){
              $('.txttbs2').html('<span class="respdis16">, soit '+toTickTxt+' billets</span>');
            }else{
              $('.txttbs2').html('<span class="respdis16">, soit '+toTickTxt+' billet</span>');
            };
            if(nbtplScan<=0){
              $('.listplb').append('<div class="messlitb">Aucun tarif présent dans votre liste</div>');
              $('.hlitb').fadeOut(0);
            }else if(nbtplScan>100){
              for(var i=0;i<nbtplScan;i++){
                var sn='';
                if(tplScan[i].infinity==true || tplScan[i].infinity=='true'){
                  sn='Illimité';
                }else{
                  sn=tplScan[i].scanNbr.toString().replace(/\B(?=(\d{3})+(?!\d))/g," ");
                };
                $('.listplb').append('<div class="litb"><div class="nameitb">'+tplScan[i].name+'</div><div class="nbscanitb">'+sn+'</div></div>')
              };
              $('.listplb').append('<div class="limoreitb">Tout afficher</div>');
            }else{
              for(var i=0;i<nbtplScan;i++){
                var sn='';
                if(tplScan[i].infinity==true || tplScan[i].infinity=='true'){
                  sn='Illimité';
                }else{
                  sn=tplScan[i].scanNbr.toString().replace(/\B(?=(\d{3})+(?!\d))/g," ");
                };
                $('.listplb').append('<div class="litb"><div class="nameitb">'+tplScan[i].name+'</div><div class="nbscanitb">'+sn+'</div></div>')
              };
            };
            // B2 + B3 + B4
            chartD=new Chartist.Pie('.ct-chartD', {
              series: [{value: 0, className: 'd1'},{value: 1, className: 'd2'}]
            }, {donut: true, showLabel: false});
            chartE=new Chartist.Pie('.ct-chartE', {
              series: [{value: 0, className: 'd1'},{value: 1, className: 'd2'}]
            }, {donut: true, showLabel: false});
            genG(typGraph);
            // B5
            if(nbAccess==0){
              $('.adressev').html('<i class="fa-regular fa-arrow-right-to-arc"></i> &nbsp;0 accès<span class="respdis30"> associé</span><span class="respdis20"> à cette liste</span>');
            }else if(nbAccess==1){
              $('.adressev').html('<i class="fa-regular fa-arrow-right-to-arc"></i> &nbsp;1 accès<span class="respdis30"> associé</span><span class="respdis20"> à cette liste</span>');
            }else{
              $('.adressev').html('<i class="fa-regular fa-arrow-right-to-arc"></i> &nbsp;'+nbAccess+' accès<span class="respdis30"> associés</span><span class="respdis20"> à cette liste</span>');
            };
            $('.listacstdb').html('');
            if(tbAccess.length==0){
              $('.listacstdb').append('<div class="noitacs">Aucun accès<span class="respdis70"> associé</span><span class="respdis60"> à cette liste.</span></div>');
            }else{
              for(var i=0;i<tbAccess.length;i++){
                var propScan=0;
                if(tbAccess[i].totalS>0){
                  if(tbAccess[i].totalS>totS){tbAccess[i].totalS=totS};
                  propScan=Math.round((tbAccess[i].totalS/totS)*100);
                };
                tbAccess[i]['propScan']=propScan;
              };
              if(sortAccess==1){
                tbAccess=tbAccess.sort(function(a,b){return (b.propScan-a.propScan)});
              }else{
                tbAccess=tbAccess.sort(function(a,b){return (a.propScan-b.propScan)});
              };
              for(var i=0;i<tbAccess.length;i++){
                var perCL=parseFloat(tbAccess[i].propScan);
                var txtperCL="";
                if(perCL>25){txtperCL='<span class="mtper">'+perCL+' %</span>';};
                $('.listacstdb').append('<div class="itacs" id="'+tbAccess[i]._id+'" tag="'+tbAccess[i].totalS+'" style="width: 100%;height:46px;line-height:46px;padding-left:4%;margin-top:3px;"><div class="nameacs">'+tbAccess[i].name+'</div><div class="jaugeacs" ><div class="progacs" style="width:'+perCL+'%">'+txtperCL+'&nbsp;&nbsp;</div></div></div>');
              };
            };
            // B6
            $('.listtpltdb').html('');
            if(tplScan.length==0){
              $('.listtpltdb').append('<div class="noittpl">Aucun tarif<span class="respdis70"> présent</span><span class="respdis60"> dans cette liste.</span></div>');
            }else{
              for(var i=0;i<tplScan.length;i++){
                var propScan=0;
                if(tplScan[i].totalS>0){
                  if(tplScan[i].totalS>tplScan[i].toScan){tplScan[i].totalS=tplScan[i].toScan};
                  propScan=Math.round((tplScan[i].totalS/tplScan[i].toScan)*100);
                };
                tplScan[i]['propScan']=propScan;
              };
              if(sortTpl==1){
                tplScan=tplScan.sort(function(a,b){return (b.propScan-a.propScan)});
              }else{
                tplScan=tplScan.sort(function(a,b){return (a.propScan-b.propScan)});
              };
              for(var i=0;i<tplScan.length;i++){
                var perCL=parseFloat(tplScan[i].propScan);
                var txtperCL="";
                if(perCL>25){txtperCL='<span class="mtper">'+perCL+' %</span>';};
                $('.listtpltdb').append('<div class="ittpl" tag="'+tplScan[i].totalS+'" style="width: 100%;height:46px;line-height:46px;padding-left:4%;margin-top:3px;"><div class="nametpl">'+tplScan[i].name+'</div><div class="jaugetpl" ><div class="progtpl" style="width:'+perCL+'%">'+txtperCL+'&nbsp;&nbsp;</div></div></div>');
              };
            };
            //
            t.boService.screenMode(darkMode,contrastMode);
            // set timeout paour attendre la fin des transitions
            setTimeout(function(){
              $('.blocLoader').fadeOut(300);
            },300);
          }).catch(error => {
            console.log(error);
            t.boService.errorLoad();
          });
        }).catch(error => {
          console.log(error);
          t.boService.errorLoad();
        });
      };
      var ndf=28;
      var md=31;
      switch (year) {
        case 2024:ndf=29;break;
        case 2028:ndf=29;break;
        case 2032:ndf=29;break;
        case 2036:ndf=29;break;
        case 2040:ndf=29;break;
        case 2044:ndf=29;break;
        case 2048:ndf=29;break;
        case 2052:ndf=29;break;
      };
      switch (month) {
        case 1:md=31;break;
        case 2:md=ndf;break;
        case 3:md=31;break;
        case 4:md=30;break;
        case 5:md=31;break;
        case 6:md=30;break;
        case 7:md=31;break;
        case 8:md=31;break;
        case 9:md=30;break;
        case 10:md=31;break;
        case 11:md=30;break;
        case 12:md=31;
      };
      var mInHour=60;
      var mInDay=1440;
      var mInMonth=md*1440;
      var mInYear=mInMonth*12;
      var hInDay=24;
      var hInMonth=md*24;
      var hInYear=hInMonth*12;
      var dInMonth=md;
      var dInYear=dInMonth*12;
      function cnt0(a,b,t,max){
        var c=0;
        var d=[];
        var nbdayim=31;
        if(t=='m'){
          c=a.dMinute-b.dMinute;
          c+=(a.dHour-b.dHour)*mInHour;
          c+=(a.dDay-b.dDay)*mInDay;
          c+=(a.dMonth-b.dMonth)*mInMonth;
          c+=(a.dYear-b.dYear)*mInYear;
          var dminute=b.dMinute;
          var dhour=b.dHour;
          var dday=b.dDay;
          var dmonth=b.dMonth;
          var dyear=b.dYear;
          var dminuteTxt=b.dMinute;
          var dhourTxt=b.dHour;
          var ddayTxt=b.dDay;
          var dmonthTxt=b.dMonth;
          if(dminuteTxt<10){dminuteTxt='0'+dminuteTxt;};
          if(dhourTxt<10){dhourTxt='0'+dhourTxt;};
          if(ddayTxt<10){ddayTxt='0'+ddayTxt;};
          if(dmonthTxt<10){dmonthTxt='0'+dmonthTxt;};
          d.push(ddayTxt+'/'+dmonthTxt+' à '+dhourTxt+'h'+dminuteTxt);
          nbdayim=nbDaysInMonth(dmonth,dyear);
          for(var u=0;u<(c-1);u++){
            if((u+max)<=maxM){
              if(dminute==59){
                dminute=0;
                if(dhour==23){
                  dhour=0;
                  if(dday==nbdayim){
                    dday=1;
                    if(dmonth==12){
                      dmonth=1;
                    }else{
                      dmonth++;
                    };
                    nbdayim=nbDaysInMonth(dmonth,dyear);
                  }else{
                    dday++;
                  };
                }else{
                  dhour++;
                };
              }else{
                dminute++;
              };
              dminuteTxt=dminute;
              dhourTxt=dhour;
              ddayTxt=dday;
              dmonthTxt=dmonth;
              if(dminuteTxt<10){dminuteTxt='0'+dminuteTxt;};
              if(dhourTxt<10){dhourTxt='0'+dhourTxt;};
              if(ddayTxt<10){ddayTxt='0'+ddayTxt;};
              if(dmonthTxt<10){dmonthTxt='0'+dmonthTxt;};
              d.push(ddayTxt+'/'+dmonthTxt+' à '+dhourTxt+'h'+dminuteTxt);
            };
          };
        }else if(t=='h'){
          c=a.dHour-b.dHour;
          c+=(a.dDay-b.dDay)*hInDay;
          c+=(a.dMonth-b.dMonth)*hInMonth;
          c+=(a.dYear-b.dYear)*hInYear;
          var dhour=b.dHour;
          var dday=b.dDay;
          var dmonth=b.dMonth;
          var dyear=b.dYear;
          var dhourTxt=b.dHour;
          var ddayTxt=b.dDay;
          var dmonthTxt=b.dMonth;
          if(dhourTxt<10){dhourTxt='0'+dhourTxt;};
          if(ddayTxt<10){ddayTxt='0'+ddayTxt;};
          if(dmonthTxt<10){dmonthTxt='0'+dmonthTxt;};
          d.push(ddayTxt+'/'+dmonthTxt+' à '+dhourTxt+'h');
          nbdayim=nbDaysInMonth(dmonth,dyear);
          for(var u=0;u<(c-1);u++){
            if((u+max)<=maxH){
              if(dhour==23){
                dhour=0;
                if(dday==nbdayim){
                  dday=1;
                  if(dmonth==12){
                    dmonth=1;
                  }else{
                    dmonth++;
                  };
                  nbdayim=nbDaysInMonth(dmonth,dyear);
                }else{
                  dday++;
                };
              }else{
                dhour++;
              };
              dhourTxt=dhour;
              ddayTxt=dday;
              dmonthTxt=dmonth;
              if(dminuteTxt<10){dminuteTxt='0'+dminuteTxt;};
              if(dhourTxt<10){dhourTxt='0'+dhourTxt;};
              if(ddayTxt<10){ddayTxt='0'+ddayTxt;};
              if(dmonthTxt<10){dmonthTxt='0'+dmonthTxt;};
              d.push(ddayTxt+'/'+dmonthTxt+' à '+dhourTxt+'h');
            };
          };
        }else if(t=='d'){
          c=a.dDay-b.dDay;
          c+=(a.dMonth-b.dMonth)*dInMonth;
          c+=(a.dYear-b.dYear)*dInYear;
          var dday=b.dDay;
          var dmonth=b.dMonth;
          var dyear=b.dYear;
          var ddayTxt=b.dDay;
          var dmonthTxt=b.dMonth;
          if(ddayTxt<10){ddayTxt='0'+ddayTxt;};
          if(dmonthTxt<10){dmonthTxt='0'+dmonthTxt;};
          d.push(ddayTxt+'/'+dmonthTxt);
          nbdayim=nbDaysInMonth(dmonth,dyear);
          for(var u=0;u<(c-1);u++){
            if((u+max)<=maxD){
              if(dday==nbdayim){
                dday=1;
                if(dmonth==12){
                  dmonth=1;
                }else{
                  dmonth++;
                };
                nbdayim=nbDaysInMonth(dmonth,dyear);
              }else{
                dday++;
              };
              ddayTxt=dday;
              dmonthTxt=dmonth;
              if(ddayTxt<10){ddayTxt='0'+ddayTxt;};
              if(dmonthTxt<10){dmonthTxt='0'+dmonthTxt;};
              d.push(ddayTxt+'/'+dmonthTxt);
            };
          };
        };
        return d;
      };
      function nbDaysInMonth(m,y){
        var nbd=0;
        switch (m) {
          case 1:nbd=31;break;
          case 2:nbd=100;break;
          case 3:nbd=31;break;
          case 4:nbd=30;break;
          case 5:nbd=31;break;
          case 6:nbd=30;break;
          case 7:nbd=31;break;
          case 8:nbd=31;break;
          case 9:nbd=30;break;
          case 10:nbd=31;break;
          case 11:nbd=30;break;
          case 12:nbd=31;
        };
        if(nbd==100){
          nbd=28;
          switch (y) {
            case 2024:nbd=29;break;
            case 2028:nbd=29;break;
            case 2032:nbd=29;break;
            case 2036:nbd=29;break;
            case 2040:nbd=29;break;
            case 2044:nbd=29;break;
            case 2048:nbd=29;break;
            case 2052:nbd=29;break;
          };
        };
        return nbd;
      };
      // Génération : ---------------------------------------------------

      // --- Fonctions / actions : -----------------------------------------------
      $('.b1').click(function(){
        var slievl=$('.slidevents').children().length;
        $('.b1').css({'transform':'scale(0.94)'});
        setTimeout(function(){
          $('.b1').css({'transform':'scale(1.04) translateY(-3px)','background-color':'#ffffff'});
        },200);
        setTimeout(function(){
          $('.b1').attr('style','');
        },800);
        setTimeout(function(){
          if(slievl!=0){
            $('.fdpop,.contblocevents').fadeIn(500);
            $('.blocevents').css({'opacity':'0.8','transform':'scale(1.0)'});
            $('.titpast').css({'opacity':'1','transform':'scale(1.0)'});
          };
        },300);
      });
      $('.btneditevT').click(function(e){
        e.stopPropagation();
        t.ngZone.run(()=>t.router.navigate(['configurer-general']));
      });
      $('.icinfoappa').click(function(){
        $('.fdpopedit,.popinfoAppa').fadeIn(400);
      });
      $('.icmorelist').click(function(){
        var tml=$(this);
        if(tml.attr('class')=='icmorelist'){
          tml.addClass('icmoreliston');
          $('.b3').addClass('b3extand');
          $('.hlitb').css({'opacity':'1.0'});
          $('.listplb').css({'height':'155px','margin-top':'20px','opacity':'1.0'});
          $('.blocinfotext').css({'height':'0px','margin-top':'0px','opacity':'0.0'});
        }else{
          tml.removeClass('icmoreliston');
          $('.b3').removeClass('b3extand');
          $('.hlitb').css({'opacity':'0.0'});
          $('.listplb').css({'height':'0px','margin-top':'0px','opacity':'0.0'});
          if(wiw>830){
            $('.blocinfotext').css({'height':'155px','margin-top':'20px','opacity':'1.0'});
          }else{
            $('.blocinfotext').css({'height':'155px','margin-top':'15px','opacity':'1.0'});
          };
        };
      });
      $('.listplb').on('click','.limoreitb',function(e){
        e.stopPropagation();
        t.ngZone.run(()=>t.router.navigate(['configurer-ma-liste']));
      });
      $('.btngoconflist').click(function(e){
        e.stopPropagation();
        t.ngZone.run(()=>t.router.navigate(['configurer-ma-liste']));
      });
      $('.btnrefreshT').click(function(e){
        e.stopPropagation();
        t.ngZone.run(()=>t.router.navigateByUrl('/redirect')).then(() => {
          t.router.navigate(['tableau-de-bord']);
        });
      });
      $('.selectb6').change(function(){
        typGraph=$(this).val();
        genG(typGraph);
      });
      function genG(a){
        dlg=0;
        var tb=[];
        var tbL=0;
        var tabM=[];
        var tabH=[];
        var tabD=[];
        var t=0;
        $('.gTM,.gTH,.gTJ,.noDataGs,.noDataGin,.noDataGout').css({'display':'none'});
        $('.listpay').html('');
        if(a=='S'){
          $('.titb2').html('Taux<span class="respdis13"> de présence</span>');
          $('.respdis15').html('Derniers contrôles ');
          $('#mtndatapay').html(totSTxt);
          if(toScan>1){
            $('.texttotparT').html(''+toScanTxt+'<span class="respdis14"> contrôles</span>');
          }else{
            $('.texttotparT').html(''+toScanTxt+'<span class="respdis14"> contrôle</span>');
          };
          if(cgs==0){
            $('.mper').html(totSPer);
            $('.mtntot').html(totSTxt);
          }else{
            $('.mper').html(totSTxt);
            $('.mtntot').html(totSPer+'<span style="font-size:14px;"> %<span>');
          };
          var newData = [{value: totS, className: 'd1'},{value: (toScan-totS), className: 'd2'}];
          chartD.update({series:newData});
          chartE.update({series:newData});
          if(tbSL>0){
            dlg=1;
            tb=tbS;
            tbL=tbSL;
            tabM=tbgSM;
            tabH=tbgSH;
            tabD=tbgSD;
            t=0;
          }else{
            dlg=0;
            $('.noDataGs').css({'display':'block'});
            $('.listpay').append('<div class="nolastc">Aucun contrôle<span class="respdis50"> à afficher</span></div>');
          };
        }else if(a=='In'){
          $('.titb2').html('Nombre<span class="respdis13"> d\'entrées</span>');
          $('.respdis15').html('Dernières entrées ');
          $('#mtndatapay').html(totInTxt);
          if(toScan>1){
            $('.texttotparT').html(''+toScanTxt+'<span class="respdis14"> contrôles</span>');
          }else{
            $('.texttotparT').html(''+toScanTxt+'<span class="respdis14"> contrôle</span>');
          };
          if(cgs==0){
            $('.mper').html(totInPer);
            $('.mtntot').html(totInTxt);
          }else{
            $('.mper').html(totInTxt);
            $('.mtntot').html(totInPer+'<span style="font-size:14px;"> %<span>');
          };
          var newData = [{value: totIn, className: 'd1'},{value: (toScan-totIn), className: 'd2'}];
          chartD.update({series:newData});
          chartE.update({series:newData});
          if(tbInL>0){
            dlg=1;
            tb=tbIn;
            tbL=tbInL;
            tabM=tbgInM;
            tabH=tbgInH;
            tabD=tbgInD;
            t=1;
          }else{
            dlg=0;
            $('.noDataGin').css({'display':'block'});
            $('.listpay').append('<div class="nolastc">Aucun contrôle<span class="respdis50"> d\'entrée<span class="respdis40"> à afficher</span></span></div>');
          };
        }else if(a=='Out'){
          $('.titb2').html('Nombre<span class="respdis13"> de sorties</span>');
          $('.respdis15').html('Dernières sorties ');
          $('#mtndatapay').html(totOutTxt);
          if(totIn>1){
            $('.texttotparT').html(''+totInTxt+'<span class="respdis14"> entrées</span>');
          }else{
            $('.texttotparT').html(''+totInTxt+'<span class="respdis14"> entrée</span>');
          };
          if(cgs==0){
            $('.mper').html(totOutPer);
            $('.mtntot').html(totOutTxt);
          }else{
            $('.mper').html(totOutTxt);
            $('.mtntot').html(totOutPer+'<span style="font-size:14px;"> %<span>');
          };
          var newData = [{value: totOut, className: 'd1'},{value: (totIn-totOut), className: 'd2'}];
          chartD.update({series:newData});
          chartE.update({series:newData});
          if(tbOutL>0){
            dlg=1;
            tb=tbOut;
            tbL=tbOutL;
            tabM=tbgOutM;
            tabH=tbgOutH;
            tabD=tbgOutD;
            t=2;
          }else{
            dlg=0;
            $('.noDataGout').css({'display':'block'});
            $('.listpay').append('<div class="nolastc">Aucun contrôle<span class="respdis50"> de sortie<span class="respdis40"> à afficher</span></span></div>');
          };
        };
        if(dlg==1){
          if(overmax[t]<1){
            ldGM=tabM.length;
            wiG = ldGM*2;
            $('.gTM').css({'width':wiG});
            var wb=(100/tabM.length);
            var hb='0px';
            var cumul=0;
            $('.gTM').html('');
            for(var u=0;u<tabM.length;u++){
              if(t==0){
                cumul=tabM[u].count;
              }else{
                cumul+=tabM[u].count;
              };
              var hc=(tabM[u].count/maxCountM[t])*100;
              if(hc<1){
                hb='1%';
              }else{
                hb=hc+'%';
              };
              $('.gTM').append('<div class="barG" index="'+u+'" value="'+tabM[u].count+'" cumul="'+cumul+'" date="'+tabM[u].date+'" style="width:'+wb+'%;height:100%;transform: scale(1.2,1.0);"><div class="g" style="height:'+hb+';width:100%;margin-left:0%;"></div></div>');
            };
          };
          if(overmax[t]<2){
            ldGH=tabH.length;
            if(overmax[t]>=1){
              wiG = ldGH*12.9;
            };
            $('.gTH').css({'width':wiG});
            var wb=(100/tabH.length);
            var hb='0px';
            var cumul=0;
            $('.gTH').html('');
            for(var u=0;u<tabH.length;u++){
              if(t==0){
                cumul=tabH[u].count;
              }else{
                cumul+=tabH[u].count;
              };
              var hc=(tabH[u].count/maxCountH[t])*100;
              if(hc<1){
                hb='1%';
              }else{
                hb=hc+'%';
              };
              $('.gTH').append('<div class="barG" index="'+u+'" value="'+tabH[u].count+'" cumul="'+cumul+'" date="'+tabH[u].date+'" style="width:'+wb+'%;height:100%;transform: scale(1.0,1.0);"><div class="g" style="height:'+hb+';width:90%;margin-left:5%;"></div></div>');
            };
          };
          if(overmax[t]<3){
            ldGD=tabD.length;
            if(overmax[t]>=2){
              wiG = ldGD*28.73;
            };
            $('.gTJ').css({'width':wiG});
            var wb=(100/tabD.length);
            var hb='0px';
            var cumul=0;
            $('.gTJ').html('');
            for(var u=0;u<tabD.length;u++){
              if(t==0){
                cumul=tabD[u].count;
              }else{
                cumul+=tabD[u].count;
              };
              var hc=(tabD[u].count/maxCountD[t])*100;
              if(hc<1){
                hb='1%';
              }else{
                hb=hc+'%';
              };
              $('.gTJ').append('<div class="barG" index="'+u+'" value="'+tabD[u].count+'" cumul="'+cumul+'" date="'+tabD[u].date+'" style="width:'+wb+'%;height:100%;transform: scale(1.0,1.0);"><div class="g" style="height:'+hb+';width:96%;margin-left:2%;"></div></div>');
            };
          };
          if(overmax[t]==3){
            zooMod='N';
            $('.txtlabZoom').html('...');
            $('.gTM,.gTH,.gTJ').css({'display':'none'});
          }else if(overmax[t]==2){
            zooMod='D';
            $('.txtlabZoom').html('Jours');
            $('.gTM,.gTH').css({'display':'none'});
            $('.gTJ').css({'display':'flex'});
          }else if(overmax[t]==1){
            zooMod='H';
            $('.txtlabZoom').html('Heures');
            $('.gTM,.gTJ').css({'display':'none'});
            $('.gTH').css({'display':'flex'});
          }else if(overmax[t]==0){
            zooMod='M';
            $('.txtlabZoom').html('Minutes');
            $('.gTH,.gTJ').css({'display':'none'});
            $('.gTM').css({'display':'flex'});
          };
          $('.sliderhandZoom').css({'margin-top':''+def+'px'});
          vi=def;
          mt=def;
          defsize=wiG;
          newsize=wiG;
          lastsize=wiG;
          var wicg=$('.contgraphT').width();
          tox=wiG-wicg;
          $('.contgraphT').animate({scrollLeft:tox},0);
          var clis='itpay';
          var ii=21;
          if(tbL<20){
            ii=tbL+1;
          };
          for(var i=(tbL-1);i>(tbL-ii);i--){
            if(i%2 == 0){clis='itpay itpayg';}else{clis='itpay';};
            var ictype='<i class="fa-solid fa-arrow-up-to-line" style="transform:rotate(90deg)"></i>';
            if(tb[i].type=='out'){
              ictype='<i class="fa-solid fa-arrow-up-from-line" style="transform:rotate(-90deg)"></i>';
            };
            var date='';
            var day=tb[i].date;
            var hours='';
            day=day.split(' ');
            hours=day[1];
            hours=hours.slice(0,-3);
            hours=hours.replace(':','h');
            day=day[0];
            day=day.split('-');
            day=day[0]+'/'+day[1];
            date=day+' à '+hours;
            $('.listpay').append('<div class="'+clis+'"><div class="typels">'+ictype+'</div><div class="namels">'+tb[i].nomEtPrenom+'</div><div class="tplls">'+tb[i].tplName+'</div><div class="datels">'+date+'</div></div>');
          };
          if(clis=='itpay'){clis='itpay itpayg'}else{clis='itpay'};
          $('.listpay').append('<div class="'+clis+'"></div>');
        };
      };
      var sht=$('.sliderhandZoom');
      var pm=0;
      var em=0;
      var mllab=0;
      var lastmt=def;
      var vt=1;
      var coefReg=1;
      $(sht).on('mousedown touchstart',function(m){
        if(m.type=='mousedown'){
          pm=m.pageY;
        }else if(m.type=='touchstart'){
          pm=m.originalEvent.touches[0].pageY;
        };
        $(sht).css({'transition-duration':'0.0s'}).addClass('shton');
        $('.labZoom').animate({'opacity':1},{queue:false,duration:300});
        wicgt=$('.contgraphT').width();
        if(wiw<=820){
          $('body').css({'overflow':'hidden'});
        };
      });
      $('.b6').on('mouseup touchend',function(m){
        if(pm!=0){
          $(sht).css({'transition-duration':'0.3s'}).removeClass('shton');
          $('.labZoom').animate({'opacity':0},{queue:false,duration:300});
          pm=0;
          vi=mt;
        };
        if(wiw<=820){
          $('body').css({'overflow':'auto'});
        };
      });
      $('.contzoom').on('mousemove touchmove',function(e){
        if(pm!=0){
          if(e.type=='mousemove'){
            em=(e.pageY)-pm;
          }else if(e.type=='touchmove'){
            em=(e.originalEvent.touches[0].pageY)-pm;
          };
          mt=vi+em;
          if(mt<8){mt=8;
          }else if(mt>62){mt=62;
          }else{};
          $(sht).css({'margin-top':''+mt+'px'});
          $('.labZoom').css({'margin-top':''+mt+'px'});
          if(dlg==1){
            if(overmax[1]!=3){
              vt=((mt)/(def));
              if(overmax[1]==2){
                newsize=wiG*(vt**2);
                coefReg=regulZ(overmax[1]);
                zooMod='D';
                $('.txtlabZoom').html('Jours');
              }else if(overmax[1]==1){
                newsize=wiG*(vt**3);
                coefReg=regulZ(overmax[1]);
              if(mt<20){
                  zooMod='D';
                  $('.txtlabZoom').html('Jours');
                  $('.gTM,.gTH').css({'display':'none'});$('.gTJ').css({'display':'flex'});
                }else if(mt>=20){
                  zooMod='H';
                  $('.txtlabZoom').html('Heures');
                  $('.gTM,.gTJ').css({'display':'none'});$('.gTH').css({'display':'flex'});
                };
              }else if(overmax[1]==0){
                newsize=wiG*(vt**5);
                coefReg=regulZ(overmax[1]);
                if(mt<20){
                  zooMod='D';
                  $('.txtlabZoom').html('Jours');
                  $('.gTM,.gTH').css({'display':'none'});$('.gTJ').css({'display':'flex'});
                }else if(mt>=20 && mt<28){
                  zooMod='H';
                  $('.txtlabZoom').html('Heures');
                  $('.gTM,.gTJ').css({'display':'none'});$('.gTH').css({'display':'flex'});
                }else if(mt>=28){
                  zooMod='M';
                  $('.txtlabZoom').html('Minutes');
                  $('.gTH,.gTJ').css({'display':'none'});$('.gTM').css({'display':'flex'});
                };
              };
              if(mt<lastmt){
                coefReg=(-coefReg+2.01);
              };
              var coefposl=(tox+(wicgt/2))/(newsize*coefReg);
              var difsize=newsize-lastsize;
              var sl=tox+(difsize*coefposl);
              if(zooMod=='D'){
                mllab=(newsize/ldGD)/2;
              }else if(zooMod=='H'){
                mllab=(newsize/ldGH)/2;
              }else if(zooMod=='M'){
                mllab=(newsize/ldGM)/2;
              };
              $('.gTM,.gTH,.gTJ').css({'width':newsize});
              $('.contgraphT').animate({'scrollLeft':sl},0);
              $('.labG').css({'margin-left':mllab});
              lastmt=mt;
              lastsize=newsize;
              tox=sl;
            };
          }else{
            if(mt<20){
              $('.txtlabZoom').html('Jours');
            }else if(mt>=20 && mt<28){
              $('.txtlabZoom').html('Heures');
            }else if(mt>=28){
              $('.txtlabZoom').html('Minutes');
            };
          };
        };
      });
      function regulZ(omax){
        var xr=1;
        // 8 -> 62
        if(omax==2){
          if(mt<15){
            xr=0.800; ////// raf
            $('.barG').css({'transform':'scale(1.2,1.0)'});$('.g').css({'width':'100%','margin-left':'0%'});
          }else if(mt>=15 && mt<20){
            xr=0.780; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'80%','margin-left':'10%'});
          }else if(mt>=20 && mt<25){
            xr=0.780; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'90%','margin-left':'5%'});
          }else if(mt>=30){
            xr=0.860; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'96%','margin-left':'2%'});
          };
        }else if(omax==1){
          if(mt<10){
            xr=0.780; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'80%','margin-left':'10%'});
          }else if(mt>=10 && mt<15){
            xr=0.780; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'90%','margin-left':'5%'});
          }else if(mt>=15 && mt<20){
            xr=0.795; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'96%','margin-left':'2%'});
          }else if(mt>=20 && mt<25){
            xr=0.795; ////// raf
            $('.barG').css({'transform':'scale(1.2,1.0)'});$('.g').css({'width':'100%','margin-left':'0%'});
          }else if(mt>=25 && mt<30){
            xr=0.800; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'80%','margin-left':'10%'});
          }else if(mt>=30 && mt<45){
            xr=0.810; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'90%','margin-left':'5%'});
          }else if(mt>=45){
            xr=0.810; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'96%','margin-left':'2%'});
          };
        }else if(omax==0){
          if(mt<12){
            xr=0.770; ////// raf
            $('.barG').css({'transform':'scale(1.8,1.0)'});$('.g').css({'width':'100%','margin-left':'0%'});
          }else if(mt>=12 && mt<15){
            xr=0.780; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'80%','margin-left':'10%'});
          }else if(mt>=15 && mt<18){
            xr=0.795; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'90%','margin-left':'5%'});
          }else if(mt>=18 && mt<20){
            xr=0.800; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'96%','margin-left':'2%'});
          }else if(mt>=20 && mt<22){
            xr=0.810; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'80%','margin-left':'10%'});
          }else if(mt>=22 && mt<25){
            xr=0.805; ////// raf
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'90%','margin-left':'5%'});
          }else if(mt>=25 && mt<28){
            xr=0.800;
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'96%','margin-left':'2%'});
          }else if(mt>=28 && mt<29){
            xr=0.830;
            $('.barG').css({'transform':'scale(2.5,1.0)'});$('.g').css({'width':'100%','margin-left':'0%'});
          }else if(mt>=29 && mt<35){
            xr=0.832;
            $('.barG').css({'transform':'scale(1.7,1.0)'});$('.g').css({'width':'100%','margin-left':'0%'});
          }else if(mt>=35 && mt<38){
            xr=0.860;
            $('.barG').css({'transform':'scale(1.2,1.0)'});$('.g').css({'width':'100%','margin-left':'0%'});
          }else if(mt>=38 && mt<42){
            xr=0.876;
            $('.barG').css({'transform':'scale(1.1,1.0)'});$('.g').css({'width':'100%','margin-left':'0%'});
          }else if(mt>=42 && mt<48){
            xr=0.893;
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'80%','margin-left':'10%'});
          }else if(mt>=48  && mt<52){
            xr=0.902;
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'90%','margin-left':'5%'});
          }else if(mt>=52 && mt<57){
            xr=0.911;
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'96%','margin-left':'2%'});
          }else if(mt>=56 && mt<59){
            xr=0.917;
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'96%','margin-left':'2%'});
          }else if(mt>=59){
            xr=0.920;
            $('.barG').css({'transform':'scale(1.0,1.0)'});$('.g').css({'width':'96%','margin-left':'2%'});
          };
        };
        return xr;
      };
      var defsl=0;
      $('.contgraphT').scroll(function(){
        tox=$('.contgraphT').scrollLeft();
        $('.labG,.dateG').css({'left':(l+(defsl-tox))});
      });
      var sw = 495;
      $(window).scroll(function (event) {
        sw = $(window).scrollTop();
        $('.labG,.dateG').css({'top':495-sw});
      });
      $('.contgraphT').mouseover(function(){
        defsl=$('.contgraphT').scrollLeft();
      });
      $('.contgraphT').mouseout(function(){
        $('.barG').css({'background-color':'rgb(255,130,57,0.0)'});
        $('.txtmtnpay').html('Total :');
        $('.labG,.dateG').animate({'opacity':0},{queue:false,duration:300});
        lvi=0;
        $('.g').css({'background-image':'linear-gradient(#ffa34d 20%,#ff6f43)'});
        if(typGraph=='S'){
          $('#mtndatapay').html(totSTxt);
          if(toScan>1){
            $('.texttotparT').html(''+toScanTxt+'<span class="respdis14"> contrôles</span>');
          }else{
            $('.texttotparT').html(''+toScanTxt+'<span class="respdis14"> contrôle</span>');
          };
          if(cgs==0){
            $('.mper').html(totSPer);
            $('.mtntot').html(totSTxt);
          }else{
            $('.mper').html(totSTxt);
            $('.mtntot').html(totSPer+'<span style="font-size:14px;"> %<span>');
          };
          var newData = [{value: totS, className: 'd1'},{value: (toScan-totS), className: 'd2'}];
          chartD.update({series:newData});
          chartE.update({series:newData});
        }else if(typGraph=='In'){
          $('#mtndatapay').html(totInTxt);
          if(toScan>1){
            $('.texttotparT').html(''+toScanTxt+'<span class="respdis14"> contrôles</span>');
          }else{
            $('.texttotparT').html(''+toScanTxt+'<span class="respdis14"> contrôle</span>');
          };
          if(cgs==0){
            $('.mper').html(totInPer);
            $('.mtntot').html(totInTxt);
          }else{
            $('.mper').html(totInTxt);
            $('.mtntot').html(totInPer+'<span style="font-size:14px;"> %<span>');
          };
          var newData = [{value: totIn, className: 'd1'},{value: (toScan-totIn), className: 'd2'}];
          chartD.update({series:newData});
          chartE.update({series:newData});
        }else if(typGraph=='Out'){
          $('#mtndatapay').html(totOutTxt);
          if(totIn>1){
            $('.texttotparT').html(''+totInTxt+'<span class="respdis14"> entrées</span>');
          }else{
            $('.texttotparT').html(''+totInTxt+'<span class="respdis14"> entrée</span>');
          };
          if(cgs==0){
            $('.mper').html(totOutPer);
            $('.mtntot').html(totOutTxt);
          }else{
            $('.mper').html(totOutTxt);
            $('.mtntot').html(totOutPer+'<span style="font-size:14px;"> %<span>');
          };
          var newData = [{value: totOut, className: 'd1'},{value: (totIn-totOut), className: 'd2'}];
          chartD.update({series:newData});
          chartE.update({series:newData});
        };
      });
      var l=0;
      $('.graphT').on('mouseover','.barG',function(){
        var t = $(this);
        var c = parseFloat(t.attr('cumul'));
        var v = t.attr('value');
        var d = t.attr('date');
        l = t.offset().left;
        t.css({'background-color':'rgb(255,130,57,0.25)'});
        var txtmtnp='';
        if(wiw>=220){
          if(zooMod=='D'){
            txtmtnp='Total à ce jour :';
          }else if(zooMod=='H'){
            txtmtnp='Total à cette heure :';
          }else if(zooMod=='M'){
            txtmtnp='Total à cette minute :';
          };
          $('.txtmtnpay').html(txtmtnp);
        };
        $('#mtndatapay').html(c.toString().replace(/\B(?=(\d{3})+(?!\d))/g," "));
        if(lvi==0){
          lvi=1;
          $('.labG,.dateG').animate({'opacity':1},{queue:false,duration:300});
        };
        $('.labG,.dateG').css({'left':l,'top':495-sw});
        $('#mtnlabG').html(v);
        $('.dateG').html(d);
        t.prevAll().children('.g').css({'background-image':'linear-gradient(#ffb451 20%,#ff8640)'});
        t.children('.g').css({'background-image':'linear-gradient(#ffb95d 20%,#ffa34d)'});
        t.nextAll().children('.g').css({'background-image':'linear-gradient(#ffa34d 20%,#ff6f43)'});
        if(wiw>820){
          var sur=toScan;
          if(typGraph=='Out'){
            sur=totIn;
          };
          var scanperT=((c/sur)*100).toFixed(0);
          if(cgs==0){
            $('.mper').html(scanperT);
            $('.mtntot').html(c.toString().replace(/\B(?=(\d{3})+(?!\d))/g," "));
          }else{
            $('.mper').html(c.toString().replace(/\B(?=(\d{3})+(?!\d))/g," "));
            $('.mtntot').html(scanperT+'<span style="font-size:14px;"> %<span>');
          };
          var newData = [{value: c, className: 'd1'},{value: (sur-c), className: 'd2'}];
          chartD.update({series:newData});
          chartE.update({series:newData});
        };
      });
      $('.btngoparT').click(function(e){
        e.stopPropagation();
        t.ngZone.run(()=>t.router.navigate(['liste-des-participants']));
      });
      var cgs=0;
      $('.contper').click(function(){
        if(cgs==0){
          cgs=1;
          $('.mper').addClass('mperswitch');
          $('.mtntot').css({'height':90,'padding-left':'4px','line-height':'70px','font-size':'35px','border-color':'#33333300'});
          $('.texttotparT').css({'height':0,'opacity':0});
          if(typGraph=='S'){
            setTimeout(function(){
              $('.mper').html(totSTxt);
              $('.sper').css({'display':'none'});
              $('.mtntot').html(totSPer+'<span style="font-size:16px;"> %<span>');
            },100);
          }else if(typGraph=='In'){
            setTimeout(function(){
              $('.mper').html(totInTxt);
              $('.sper').css({'display':'none'});
              $('.mtntot').html(totInPer+'<span style="font-size:16px;"> %<span>');
            },100);
          }else if(typGraph=='Out'){
            setTimeout(function(){
              $('.mper').html(totOutTxt);
              $('.sper').css({'display':'none'});
              $('.mtntot').html(totOutPer+'<span style="font-size:16px;"> %<span>');
            },100);
          };
        }else{
          cgs=0;
          $('.mper').removeClass('mperswitch');
          $('.mtntot').css({'height':40,'padding-left':'0px','line-height':'20px','font-size':'24px','border-color':'#3333337a'});
          $('.texttotparT').css({'height':50,'opacity':1});
          if(typGraph=='S'){
            setTimeout(function(){
              $('.mper').html(totSPer);
              $('.sper').css({'display':'inline-block'});
              $('.mtntot').html(totSTxt);
            },100);
          }else if(typGraph=='In'){
            setTimeout(function(){
              $('.mper').html(totInPer);
              $('.sper').css({'display':'inline-block'});
              $('.mtntot').html(totInTxt);
            },100);
          }else if(typGraph=='Out'){
            setTimeout(function(){
              $('.mper').html(totOutPer);
              $('.sper').css({'display':'inline-block'});
              $('.mtntot').html(totOutTxt);
            },100);
          };
        };
      });
      $('.btnswitchacs').click(function(){
        $('.bloctpltdb').removeClass('blocopen');
        $('.blocaccestdb').addClass('blocopen');
      });
      $('.btnswitchtpl').click(function(){
        $('.blocaccestdb').removeClass('blocopen');
        $('.bloctpltdb').addClass('blocopen');
      });
      if(wiw<=280){
        $('.blocaccestdb').click(function(){
          $('.bloctpltdb').removeClass('blocopen');
          $('.blocaccestdb').addClass('blocopen');
        });
        $('.bloctpltdb').click(function(){
          $('.blocaccestdb').removeClass('blocopen');
          $('.bloctpltdb').addClass('blocopen');
        });
      };
      $('.btntriacstdb').click(function(){
        if(sortAccess==1){
          $(this).html('nombre de contrôle &nbsp;<i class="fas fa-chevron-up"></i>');
          sortAccess=2;
        }else{
          $(this).html('nombre de contrôle &nbsp;<i class="fas fa-chevron-down"></i>');
          sortAccess=1;
        };
        $('.listacstdb').html('');
        if(tbAccess.length==0){
          $('.listacstdb').append('<div class="noitacs">Aucun accès<span class="respdis70"> associé</span><span class="respdis60"> à cette liste.</span></div>');
        }else{
          if(sortAccess==1){
            tbAccess=tbAccess.sort(function(a,b){return (b.propScan-a.propScan)});
          }else{
            tbAccess=tbAccess.sort(function(a,b){return (a.propScan-b.propScan)});
          };
          for(var i=0;i<tbAccess.length;i++){
            var perCL=parseFloat(tbAccess[i].propScan);
            var txtperCL="";
            if(perCL>25){txtperCL='<span class="mtper">'+perCL+' %</span>';};
            $('.listacstdb').append('<div class="itacs" id="'+tbAccess[i]._id+'" tag="'+tbAccess[i].totalS+'" style="width: 100%;height:46px;line-height:46px;padding-left:4%;margin-top:3px;"><div class="nameacs">'+tbAccess[i].name+'</div><div class="jaugeacs" ><div class="progacs" style="width:'+perCL+'%">'+txtperCL+'&nbsp;&nbsp;</div></div></div>');
          };
        };
      });
      $('.listacstdb').on('mouseover','.itacs',function(){
        var mtnnbc=$(this).attr('tag');
        var pot = $(this).children('.jaugeacs').offset().top;
        var pol = $(this).children('.jaugeacs').offset().left;
        $('.nbcpa').css({'opacity':1,'top':pot,'left':pol});
        $('#mtnnbcpa').html(mtnnbc);
      });
      $('.listacstdb').mouseout(function(){
        $('.nbcpa').css({'opacity':0});
      });
      $('.iceditaccestdb').click(function(e){
        e.stopPropagation();
        t.ngZone.run(()=>t.router.navigate(['gerer-les-acces']));
      });
      $('.btntritpltdb').click(function(){
        if(sortTpl==1){
          $(this).html('proportion contrôlée &nbsp;<i class="fas fa-chevron-up"></i>');
          sortTpl=2;
        }else{
          $(this).html('proportion contrôlée &nbsp;<i class="fas fa-chevron-down"></i>');
          sortTpl=1;
        };
        $('.listtpltdb').html('');
        if(tplScan.length==0){
          $('.listtpltdb').append('<div class="noittpl">Aucun tarif<span class="respdis70"> présent</span><span class="respdis60"> dans cette liste.</span></div>');
        }else{
          if(sortTpl==1){
            tplScan=tplScan.sort(function(a,b){return (b.propScan-a.propScan)});
          }else{
            tplScan=tplScan.sort(function(a,b){return (a.propScan-b.propScan)});
          };
          for(var i=0;i<tplScan.length;i++){
            var perCL=parseFloat(tplScan[i].propScan);
            var txtperCL="";
            if(perCL>25){txtperCL='<span class="mtper">'+perCL+' %</span>';};
            $('.listtpltdb').append('<div class="ittpl" tag="'+tplScan[i].totalS+'" style="width: 100%;height:46px;line-height:46px;padding-left:4%;margin-top:3px;"><div class="nametpl">'+tplScan[i].name+'</div><div class="jaugetpl" ><div class="progtpl" style="width:'+perCL+'%">'+txtperCL+'&nbsp;&nbsp;</div></div></div>');
          };
        };
      });
      $('.listtpltdb').on('mouseover','.ittpl',function(){
        var mtnnbc=$(this).attr('tag');
        var pot = $(this).children('.jaugetpl').offset().top;
        var pol = $(this).children('.jaugetpl').offset().left;
        $('.nbcpt').css({'opacity':1,'top':pot,'left':pol});
        $('#mtnnbcpt').html(mtnnbc);
      });
      $('.listtpltdb').mouseout(function(){
        $('.nbcpt').css({'opacity':0});
      });
      $('.icedittpltdb').click(function(e){
        e.stopPropagation();
        t.ngZone.run(()=>t.router.navigate(['configurer-ma-liste']));
      });
      var vslidebas=0;
      var vicbas=1;
      $('.arrbasleft').click(function(){
        if(vslidebas>=0){}else{
          vslidebas+=100;
          vicbas--;
          $('.slidebas').css({'margin-left':''+vslidebas+'%'});
          $('.fa-circle').attr('class','far fa-circle');
          $('#icbas'+vicbas+'').attr('class','fas fa-circle');
        };
      });
      $('.arrbasright').click(function(){
        if(vslidebas<=-400){}else{
          vslidebas-=100;
          vicbas++;
          $('.slidebas').css({'margin-left':''+vslidebas+'%'});
          $('.fa-circle').attr('class','far fa-circle');
          $('#icbas'+vicbas+'').attr('class','fas fa-circle');
        };
      });
      $('.fa-circle').click(function(){
        var gibas=$(this).attr('tag');
        vicbas=parseInt(gibas);
        vslidebas=-((vicbas*100)-100);
        $('.slidebas').css({'margin-left':''+vslidebas+'%'});
        $('.fa-circle').attr('class','far fa-circle');
        $(this).attr('class','fas fa-circle');
      });
      $('.slidebas').on('click','.linkbasGac',function(e){
        e.stopPropagation();
        t.ngZone.run(()=>t.router.navigate(['gerer-les-acces']));
      });
      $('.slidebas').on('click','.linkbasMlc',function(e){
        e.stopPropagation();
        $('.multievbtn').trigger('click');
      });
      $('.slidebas').on('click','.linkbasHelp',function(e){
        e.stopPropagation();
        t.ngZone.run(()=>t.router.navigate(['aide']));
      });
      $('.morerist').click(function(){
        $('.sliderist').css({'margin-left':'-100%'});
      });
      $('.lessrist').click(function(){
        $('.sliderist').css({'margin-left':'-0%'});
      });
      $('.btnphoneT').click(function(){
        $('.fdpopedit,.popinfoTel').fadeIn(400);
      });
      $('.bs1').mouseover(function(){
        $('#hovser').html('<span style="color:#4e5bce">Billetterie en ligne</span>');
      });
      $('.bs2').mouseover(function(){
        $('#hovser').html('<span style="color:#1275c9">Billetterie sur place</span>');
      });
      $('.bs3').mouseover(function(){
        $('#hovser').html('<span style="color:#f28c29">Contrôle des accès</span>');
      });
      $('.bs4').mouseover(function(){
        $('#hovser').html('<span style="color:#e83a43">Paiement cashless</span>');
      });
      $('.b10').mouseout(function(){
        $('#hovser').html('');
      });
      var ss4=0;
      $('.s4').scroll(function(){
        ss4=$(this).scrollTop();
        if(ss4>43){
          $('.hs4').css({'border-radius': '0px'});
        }else{
          $('.hs4').css({'border-radius': '12px 12px 0px 0px'});
        };
      });
      $('.fdpopedit').click(function(){
        $('.fdpopedit,.popinfoAppa,.popinfoTel').fadeOut(400);
      });
    });
  };
};
