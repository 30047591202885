import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth.guard.service';
import { LoginComponent } from './login/login.component';
import { NouveauCompteComponent } from './nouveau-compte/nouveau-compte.component';
import { TableauDeBordComponent } from './tableau-de-bord/tableau-de-bord.component';
import { ListeDesParticipantsComponent } from './liste-des-participants/liste-des-participants.component';
import { ConfigurerGeneralComponent } from './configurer-general/configurer-general.component';
import { ConfigurerMaListeComponent } from './configurer-ma-liste/configurer-ma-liste.component';
import { MesInformationsComponent } from './mes-informations/mes-informations.component';
import { ParametresComponent } from './parametres/parametres.component';
import { AideComponent } from './aide/aide.component';
import { GererLesAccesComponent } from './gerer-les-acces/gerer-les-acces.component';
import { SupportComponent } from './support/support.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SignAlertComponent } from './sign-alert/sign-alert.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'nouveau-compte', component: NouveauCompteComponent},
  {path: 'tableau-de-bord', canActivate: [AuthGuardService], component: TableauDeBordComponent},
  {path: 'liste-des-participants', canActivate: [AuthGuardService], component: ListeDesParticipantsComponent},
  {path: 'configurer-general', canActivate: [AuthGuardService], component: ConfigurerGeneralComponent},
  {path: 'configurer-ma-liste', canActivate: [AuthGuardService], component: ConfigurerMaListeComponent},
  {path: 'gerer-les-acces', canActivate: [AuthGuardService], component: GererLesAccesComponent},
  {path: 'mes-informations', canActivate: [AuthGuardService], component: MesInformationsComponent},
  {path: 'parametres', canActivate: [AuthGuardService], component: ParametresComponent},
  {path: 'aide', canActivate: [AuthGuardService], component: AideComponent},
  {path: 'support', canActivate: [AuthGuardService], component: SupportComponent},
  {path: 'redirect', canActivate: [AuthGuardService], component: RedirectComponent},
  {path: 'deconnecte', component: SignAlertComponent},
  {path: '', redirectTo: '/login', pathMatch: 'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
