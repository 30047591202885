import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BoService } from '../services/bo.service';
import 'jquery';
import { ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService, private boService: BoService, private router: Router, private ngZone:NgZone) { }

  ngOnInit() {
    let t = this;
    $(document).ready(function(){
      $('.menubo,.headerbo').css({'display':'none'});
      $('body').css({'overflow':'hidden'});
      $('.fdpopedit').fadeOut(0);
      var cdfd=localStorage.getItem('decoForList');
      if(cdfd=='1'){
        localStorage.setItem('decoForList','');
        $('.bloclog').fadeOut(400);
        $('.headae').fadeOut(0);
        $('.fdlog').css({'background-position':'99%'});
        $('.titfirstev').html('<span style="font-size:25px;"><i class="far fa-clipboard-check"></i> &nbsp;Créez votre première liste de contrôle</span>').css({'color':'#ffffff'});
        $('.bottomae').css({'top':'440px'});
        $('.blocaddevent').css({'height':'440px'}).fadeIn(400);
      }else{
        $('.blocaddevent').fadeOut(0);
        $('.contblocae').css({'height':'670px'});
      };
      $('.inlogmail').keyup(function(){
        if($(this).val()!=''){
          $('.inlogmail').removeClass('signalinvide').attr('placeholder','');
        };
      });
      $('.inlogpassword').keyup(function(){
        if($(this).val()!=''){
          $('.inlogpassword').removeClass('signalinvide').attr('placeholder','');
        };
      });
      var modpaslost=0;
      $('.mdplost').click(function(){
        modpaslost=1;
        setTimeout(function(){
          $('.textlostpass').fadeIn(300);
          $('.btnlog').html('Confirmer').css({'transition-duration':'0.0s','margin-top':'0px'});
          $('.texttocreate').css({'opacity':'1.0'});
          $('.texttocreate').html('<i class="fas fa-chevron-left"></i> &nbsp;Retour à la page de connexion');
          $('.titfirstev').fadeIn(300);
          $('.titfirstev').html('<img title="Wilout Control" src="./assets/img/wilout_control_logo_small.svg" width="40px"><br><span class="resetxt">Réinitialiser votre mot de passe</span>')
        },400);
        $('.lablogmail').animate({'margin-top':'60px'},400);
        $('.lablogpass,.inlogpassword,.lookPass').fadeOut(400);
        $('.bloclog').animate({'height':390},400);
        $('.texttocreate').css({'opacity':'0.0'});
        $('.bloclog>.headedit').fadeOut(400);
        $('.btnlog').css({'margin-top':'-110px'});
        $('.titfirstev').fadeOut(400);
      });
      $('.lookPass').click(function(){
        var lpv=$('.lookPass').attr('id');
        if(lpv=='lookPassOff'){
          $('.inlogpassword').attr('type','text');
          $('.lookPass').attr('id','lookPassOn').html('<i class="fad fa-eye-slash"></i>');
        }else{
          $('.inlogpassword').attr('type','password');
          $('.lookPass').attr('id','lookPassOff').html('<i class="fad fa-eye"></i>');
        };
      });
      $('.texttocreate').click(function(){
        if(modpaslost==0){
          window.open('https://www.wilout.com/creer-un-compte', '_blank');
        }else{
          setTimeout(function(){$('.btnlog').css({'transition-duration':'0.4s'});},500);
          setTimeout(function(){
            $('.bloclog>.headedit').fadeIn(300);
            $('.lablogpass,.inlogpassword,.lookPass').fadeIn(300);
            $('.btnlog').css({'transition-duration':'0.0s','margin-top':'0px'});
            $('.texttocreate').css({'opacity':'1.0'});
            $('.texttocreate').html('Pas encore inscrit ?<br>Créez-vous un compte gratuitement.');
            $('.titfirstev').fadeIn(300);
            $('.titfirstev').html('<img title="Wilout Control" src="./assets/img/wilout_control_logo_small.svg" width="40px"><br>Accédez à votre espace')
          },400);
          $('.textlostpass').fadeOut(400);
          $('.lablogmail').animate({'margin-top':'20px'},400);
          $('.bloclog').animate({'height':460},400);
          $('.texttocreate').css({'opacity':'0.0'});
          $('.btnlog').css({'transition-duration':'0.4s','margin-top':'110px'});
          $('.titfirstev').fadeOut(300);
          modpaslost=0;
        };
      });
      $(document).on('keypress',function(e) {
        if(e.which == 13){$('.btnlog').trigger('click');};
      });
      var checlic=0;
      var re=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      $('.btnlog').click(function(){
        if(checlic==0){
          checlic=1;
          var getmail=$('.inlogmail').val();
          var getpass=$('.inlogpassword').val();
          $('.texterrorlog').html('');
          if(modpaslost==1){
            if(getmail==''){
              $('.inlogmail').addClass('signalinvide');
              checlic=0;
            }else{
              $(this).html('Chargement...<div class="loaderlogin"></div>');
              $('.loaderlogin').animate({'width':'40%'},300);
              /* t.authService.passwordLost(getmail).then((data) => {
                $('.texterrorlog').html('<div style="line-height:30px;color:#2fbb5d"><br><span style="font-size:16px;font-weight:800">Votre mot de passe a été réinitialisé &nbsp;<i class="far fa-check-circle"></i></span> <br>Consultez votre boite mail pour récupérer vos nouveaux accès.</div>');
                $(this).css({'opacity':'0'}).html('Se connecter &nbsp;<i class="fas fa-sign-in-alt"></i>');
                $('.loaderlogin').css({'width':0});
                setTimeout(function(){
                  setTimeout(function(){$('.btnlog').css({'transition-duration':'0.4s'});},500);
                  setTimeout(function(){
                    $('.bloclog>.headedit').fadeIn(300);
                    $('.lablogpass,.inlogpassword').fadeIn(300);
                    $('.btnlog').css({'transition-duration':'0.0s','margin-top':'0px'});
                    $('.texttocreate').css({'opacity':'1.0'});
                    $('.texttocreate').html('Pas encore inscrit ?<br>Créez-vous un compte gratuitement.');
                    $('.titfirstev').fadeIn(300);
                    $('.titfirstev').html('<img title="Wilout Control" src="./assets/img/wilout_control_logo_small.svg" width="40px"><br>Accédez à votre espace')
                  },400);
                  $('.textlostpass').fadeOut(400);
                  $('.lablogmail').animate({'margin-top':'20px'},400);
                  $('.bloclog').animate({'height':460},400);
                  $('.texttocreate').css({'opacity':'0.0'});
                  $('.btnlog').css({'transition-duration':'0.4s','margin-top':'110px','opacity':1});
                  $('.titfirstev').fadeOut(300);
                  modpaslost=0;
                },3000);
              }).catch(error => {
                $(this).html('Confirmer');
                $('.loaderlogin').css({'width':0});
              }); */
            };
          };
          if(getmail==''&&getpass==''){
            $('.inlogmail,.inlogpassword').addClass('signalinvide').attr('placeholder','Ce champ est requis');
            checlic=0;
          }else if(getmail==''){
            $('.inlogmail').addClass('signalinvide');
            checlic=0;
          }else if(getpass==''){
            $('.inlogpassword').addClass('signalinvide');
            checlic=0;
          }else if(!getmail.match(re)){
            $('.inlogmail').addClass('signalinvide');$('.texterrorlog').html('<i class="far fa-exclamation-triangle"></i> &nbsp;Veuillez indiquer une adresse e-mail valide');
            checlic=0;
          }else{
            $(this).html('Chargement...<div class="loaderlogin"></div>');
            $('.loaderlogin').animate({'width':'40%'},1500);
            t.authService.signIn(getmail,getpass).then((data) => {
              localStorage.setItem('authUser','true');
              localStorage.setItem('idUser',JSON.stringify(data['user']['_id']));
              localStorage.setItem('infoUser',JSON.stringify(data['user']));
              $('.loaderlogin').animate({'width':'80%'},2500);
              t.boService.getAllLists('0').then((lc) => {
                $('.loaderlogin').animate({'width':'100%'},100);
                if(Object.keys(lc).length>0){
                  t.ngZone.run(()=>t.router.navigate(['tableau-de-bord']));
                }else{
                  $('.bloclog').fadeOut(400);
                  $('.headae').fadeOut(0);
                  $('.fdlog').css({'background-position':'99%'});
                  $('.titfirstev').html('<span style="font-size:25px;"><i class="far fa-clipboard-check"></i> &nbsp;Créez votre première liste de contrôle</span>').css({'color':'#ffffff'});
                  $('.bottomae').css({'top':'440px'});
                  $('.blocaddevent').css({'height':'440px'}).fadeIn(400);
                };
              }).catch(error => {
                console.log('Erreur de chargement des listes de contrôle ( '+error+' )');
                checlic=0;
                $(this).html('Se connecter &nbsp;<i class="fas fa-sign-in-alt"></i>');
                $('.loaderlogin').css({'width':0});
              });
            }).catch(error => {
              console.log(error);
              if(error.error.message.user=='Email ou password incorrect'){
                checlic=0;
                $('.texterrorlog').html('<i class="far fa-exclamation-triangle"></i> &nbsp;Mot de passe incorrect');
                $(this).html('Se connecter &nbsp;<i class="fas fa-sign-in-alt"></i>');
              }else{
                checlic=0;
                $('.texterrorlog').html('<i class="far fa-exclamation-triangle"></i> &nbsp;Identifiant inconnu');
                $(this).html('Se connecter &nbsp;<i class="fas fa-sign-in-alt"></i>');
              };
              $('.loaderlogin').css({'width':0});
            });
          };
        };
      });
    });
  }

}