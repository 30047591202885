import { Component, OnInit } from '@angular/core';
import 'jquery';
import { ViewEncapsulation } from '@angular/core';
import { BoService } from '../services/bo.service';
declare var $: any;

@Component({
  selector: 'app-parametres',
  templateUrl: './parametres.component.html',
  styleUrls: ['./parametres.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ParametresComponent implements OnInit {

  constructor(private boService: BoService) { }

  ngOnInit() {
    let t = this;
    $(document).ready(function(){
      // --- Initialisation globale : --------------------------------------------
      $('.menubo,.headerbo').css({'display':'block'});
      var wiw=$(window).width();
      $('#htitsec').html('Mon compte');
      $('#htitsec2').html('Paramètres');
      $('.menubs').attr('id','');$('.menubs-5').attr('id','actif1');
      $('.secmenu2bs').attr('id','');$('.5s2').attr('id','actif2');
      if(wiw>610){
        $('#htitsec,#htitsec2,#fles').css({'display':'inline'});
      }else{
        $('#htitsec2').css({'display':'inline'});
        $('#fles').css({'display':'none'});
      };
      $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
      $(window).scroll(function(){
        var sw= $(this).scrollTop();
        if(sw>110){
          $('.headerbo').css({'border-color':'#b4aaaa00','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.05)'});
        }else{
          $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
        };
      });
      $('body').css({'overflow':'hidden'});
      $(window).scrollTop(0);
      //--------------------------------------------------------------------------
      // --- Recuperation et création des data utiles pour jq : ------------------
      var infoUser0={};
      var infoUser={};
      var modTab=[false,false,false,false,false,false,false,false,false,false,false];
      var idx=0;
      var darkMode='off';
      var contrastMode='off';
      infoUser0=JSON.parse(localStorage.getItem('infoUser'));
      loadParams(infoUser0);
      t.boService.screenMode(darkMode,contrastMode)
      $('.blocLoader').fadeOut(300);
      /* t.boService.getInfoUser().then(data => {
        loadParams(data);
        $('.blocLoader').fadeOut(300);
      }).catch(error => {
        t.boService.errorLoad();
      }); */
      function loadParams(x){
        if(x._id!=undefined){$('#inidentifiant').html(x._id);};
        if(x.synchroCode!=undefined){$('#insynchrocode').val(x.synchroCode);};
        if(x.darkMode!=undefined){
          if(x.darkMode=='on'){
            darkMode='on';
            $('#indark').addClass('inchon');
          }else{
            $('#indark').removeClass('inchon');
          };
        }else{
          $('#indark').removeClass('inchon');
        };
        if(x.contrastMode!=undefined){
          if(x.contrastMode=='on'){
            contrastMode='on';
            $('#incontrast').addClass('inchon');
          }else{
            $('#incontrast').removeClass('inchon');
          };
        }else{
          $('#incontrast').removeClass('inchon');
        };
        if(x.language!=undefined){
          if(x.language=='fr'){
            $('.listSpeack').css({'margin-top':'0px'});
            $('#itsp1').addClass('itSpeackOn');
          }else if(x.language=='en'){
            $('.listSpeack').css({'margin-top':'-50px'});
            $('#itsp2').addClass('itSpeackOn');
          }else if(x.language=='es'){
            $('.listSpeack').css({'margin-top':'-100px'});
            $('#itsp3').addClass('itSpeackOn');
          }else{
            $('.listSpeack').css({'margin-top':'0px'});
            $('#itsp1').addClass('itSpeackOn');
          };
        }else{
          $('.listSpeack').css({'margin-top':'0px'});
          $('#itsp1').addClass('itSpeackOn');
        };
        if(x.dateRegister!=undefined){$('.datecrea').html(x.dateRegister);};
      };
      function storageSizeFn(){
        var allStrings = '';
        for(var key in localStorage){
            if(localStorage.hasOwnProperty(key)){
                allStrings += localStorage[key];
            }
        }
        var localStorageSize = ((3 + ((allStrings.length*16)/(8*1024)))/1000).toFixed(2)+' MB';
        $('.storageSize').html(localStorageSize);
      };
      storageSizeFn();
      //--------------------------------------------------------------------------
      // --- Fonctions / actions  : ----------------------------------------------
      /* $('.btneditpass').click(function(){
        $('.fdpopedit,.popChangePass').fadeIn(500);
      });
      $('.fdpopedit,.closepopChangePass').click(function(){
        $('.fdpopedit,.popChangePass').fadeOut(500);
      });
      var oldpass='';
      var newpass='';
      $('.incpass').keyup(function(){
        oldpass=$('.oldpass').val();
        newpass=$('.newpass').val();
        if(oldpass.length>0&&newpass.length>0){
          $('.btnvalidpass').removeClass('btnvalidpassoff');
        }else{
          $('.btnvalidpass').addClass('btnvalidpassoff');
        };
      });
      $('.oldpass').change(function(){
        $(this).removeClass('signalinvide').attr('placeholder','');
      });
      $('.newpass').change(function(){
        $(this).removeClass('signalinvide').attr('placeholder','6 caractères minimum');
        $('.texterrorpass').html('');
      });
      var checlic12=0;
      $('.btnvalidpass').click(function(){
        if(checlic12==0){
          checlic12=1;
          var checregle=0;
          var re=/^[ t]*$/;
          $('.texterrorpass').html('');
          if($('.oldpass').val()==''||$('.oldpass').val().match(re)){$('.oldpass').addClass('signalinvide').attr('placeholder','Ce champ est requis');checlic12=0;checregle=1};
          if($('.newpass').val()==''||$('.newpass').val().match(re)){$('.newpass').addClass('signalinvide').attr('placeholder','Ce champ est requis');checlic12=0;checregle=1};
          if($('.newpass').val().length<6){$('.newpass').addClass('signalinvide');$('.texterrorpass').html('Votre mot de passe doit comprendre au moins 6 caractères');checlic12=0;checregle=1;};
          if(checregle==0){
            $('.btnvalidpass').html('Sauvegarde en cours...');
            $('.savestick').css({'right':'0px'});
            oldpass=$('.oldpass').val();
            newpass=$('.newpass').val();
            t.boService.newPassword(oldpass,newpass).then(data => {
              $('.btnvalidpass').html('Confirmer');
              $('.savestick').css({'right':'-260px'});
              $('.fdpopedit,.popChangePass').fadeOut(500);
              $('.oldpass').val('');
              $('.newpass').val('');
              checlic12=0;
            }).catch(error => {
              $('.texterrorpass').html('<i class="far fa-exclamation-triangle"></i> &nbsp;Échec de la modification du mot de passe');
              $('.btnvalidpass').html('Confirmer');
              $('.savestick').css({'right':'-260px'});
              checlic12=0;
            });
          };
        };
      }); */
      $('.shareweb').click(function(){
        var t=$(this);
        t.html('<i class="fas fa-check"></i> &nbsp;Lien copié');
        setTimeout(function(){
          t.html('<i class="fas fa-link"></i> &nbsp;Copier lien');
        },1500);
        var c='<p>https://www.wilout.com/controle-des-acces</p>';
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(c).text()).select();
        document.execCommand("copy");
        $temp.remove();
      });
      $('#insynchrocode').keyup(function(){
        var valin=$(this).val();
        infoUser0['synchroCode']=valin;
        infoUser['synchroCode']=valin;
        modTab[0]=true;
        countMod();
      });
      var checlicD=0;
      $('#indark').change(function(){
        if(checlicD==0){
          checlicD=1;
          $('.savestick').css({'right':'0px'});
          if($(this).attr('class')=='inchon'){
            $('#indark').removeClass('inchon');
            infoUser0['darkMode']='off';
            infoUser['darkMode']='off';
            t.boService.screenMode('off',contrastMode);
          }else{
            $('#indark').addClass('inchon');
            infoUser0['darkMode']='on';
            infoUser['darkMode']='on';
            t.boService.screenMode('on',contrastMode);
          };
          t.boService.editInfoUser(infoUser).then(data => {
            localStorage.setItem('infoUser',JSON.stringify(infoUser0));
            infoUser={};
            $('.savestick').css({'right':'-260px'});
            if(infoUser0['darkMode']=='off'){
              window.location.reload();
            };
            checlicD=0;
          }).catch(error => {
            checlicD=0;
          });
        };
      });
      var checlicC=0;
      $('#incontrast').change(function(){
        if(checlicC==0){
          checlicC=1;
          $('.savestick').css({'right':'0px'});
          if($(this).attr('class')=='inchon'){
            $('#incontrast').removeClass('inchon');
            infoUser0['contrastMode']='off';
            infoUser['contrastMode']='off';
            t.boService.screenMode(darkMode,'off');
          }else{
            $('#incontrast').addClass('inchon');
            infoUser0['contrastMode']='on';
            infoUser['contrastMode']='on';
            t.boService.screenMode(darkMode,'on');
          };
          t.boService.editInfoUser(infoUser).then(data => {
            localStorage.setItem('infoUser',JSON.stringify(infoUser0));
            infoUser={};
            $('.savestick').css({'right':'-260px'});
            if(infoUser0['contrastMode']=='off'){
              window.location.reload();
            };
            checlicC=0;
          }).catch(error => {
            checlicC=0;
          });
        };
      });
      var chsp=1;
      $('#itsp1').click(function(){
        chsp=1;
        $('.itSpeackOn').removeClass('itSpeackOn');
        $(this).addClass('itSpeackOn');
        infoUser0['language']='fr';
        infoUser['language']='fr';
        t.boService.editInfoUser(infoUser).then(data => {
          localStorage.setItem('infoUser',JSON.stringify(infoUser0));
          infoUser={};
        }).catch(error => {});
      });
      $('#itsp2').click(function(){
        chsp=2;
        $('.itSpeackOn').removeClass('itSpeackOn');
        $(this).addClass('itSpeackOn');
        infoUser0['language']='en';
        infoUser['language']='en';
        t.boService.editInfoUser(infoUser).then(data => {
          localStorage.setItem('infoUser',JSON.stringify(infoUser0));
          infoUser={};
        }).catch(error => {});
      });
      $('#itsp3').click(function(){
        chsp=3;
        $('.itSpeackOn').removeClass('itSpeackOn');
        $(this).addClass('itSpeackOn');
        infoUser0['language']='es';
        infoUser['language']='es';
        t.boService.editInfoUser(infoUser).then(data => {
          localStorage.setItem('infoUser',JSON.stringify(infoUser0));
          infoUser={};
        }).catch(error => {});
      });
      $('.speakbox').mouseover(function(){
        $(this).addClass('speakboxOpen');
        $('.listSpeack').css({'margin-top':'0px'});
      }).mouseout(function(){
        $(this).removeClass('speakboxOpen');
        if(chsp==1){
          $('.listSpeack').css({'margin-top':'0px'});
        }else if(chsp==2){
          $('.listSpeack').css({'margin-top':'-50px'});
        }else if(chsp==3){
          $('.listSpeack').css({'margin-top':'-100px'});
        };
      });
      function countMod(){
        var e=0;
        for(var i=0;i<modTab.length;i++){
          if(modTab[i]==true){
            e++;
          };
        };
        if(e==0){
          $('.blocmodifuser').html('');
        }else if(e==1){
          $('.blocmodifuser').html(e+' modification non-sauvegardée')
        }else if(e>1){
          $('.blocmodifuser').html(e+' modifications non-sauvegardées')
        };
      };
      var checlic=0;
      $('.btnsaveparam').click(function(){
        // en attendant car rien à sauvegarder avec le btn pour le moment :
        $(this).html('Sauvegarde en cours...');
        setTimeout(function(){
          $('.btnsaveparam').html('<i class="fas fa-save"></i>&nbsp; Sauvegarder ');
        },300);
        /* 
        if(checlic==0){
          checlic=1;
          $(this).html('Sauvegarde en cours...');
         t.boService.editInfoUser(infoUser).then(data => {
            localStorage.setItem('infoUser',JSON.stringify(infoUser0));
            infoUser={};
            $('.blocmodifuser').html('');
            $('.btnsaveparam').html('<i class="fas fa-save"></i>&nbsp; Sauvegarder ');
            checlic=0;
          }).catch(error => {
            checlic=0;
          }); 
        };
        */
      });
    });
  };

}