import { Component, OnInit } from '@angular/core';
import 'jquery';
import { ViewEncapsulation } from '@angular/core';
import { BoService } from '../services/bo.service';
declare var $: any;

@Component({
  selector: 'app-aide',
  templateUrl: './aide.component.html',
  styleUrls: ['./aide.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AideComponent implements OnInit {

  constructor(private boService: BoService) { }
  
  ngOnInit() {
    let t = this;
    $(document).ready(function(){
      // --- Initialisation globale : --------------------------------------------
      $('.menubo,.headerbo').css({'display':'block'});
      var darkMode='off';
      var contrastMode='off';
      if(JSON.parse(localStorage.getItem('infoUser')).darkMode=='on'){darkMode='on'};
      if(JSON.parse(localStorage.getItem('infoUser')).contrastMode=='on'){contrastMode='on'};
      var wiw=$(window).width();
      $('#htitsec').html('Assistance');
      $('#htitsec2').html('Aide et astuces');
      $('.menubs').attr('id','');$('.menubs-6').attr('id','actif1');
      $('.secmenu2bs').attr('id','');$('.6s1').attr('id','actif2');
      if(wiw>610){
        $('#htitsec,#htitsec2,#fles').css({'display':'inline'});
      }else{
        $('#htitsec2').css({'display':'inline'});
        $('#fles').css({'display':'none'});
      };
      $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
      $(window).scroll(function(){
        var sw= $(this).scrollTop();
        if(sw>60){
          $('.headerbo').css({'border-color':'#b4aaaa00','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.05)'});
        }else{
          $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
        };
      });
      $('body').css({'overflow':'auto'});
      $(window).scrollTop(0);
      //--------------------------------------------------------------------------
      // --- Recuperation et création des data utiles pour jq : ------------------
      var dataHelp;
      var cus=['<span style="color:#ff8239;background-color:#ff823930">','</span>'];
      dataHelp=t.boService.helpBook;
      loadHelp(dataHelp);
      t.boService.screenMode(darkMode,contrastMode);
      $('.blocLoader').fadeOut(0);
      function loadHelp(x){
        $('.conttopic').html('');
        if(x.length==0){
          $('.conttopic').html('<div class="searchNull"><i class="far fa-info-circle"></i> &nbsp;Auncun contenu ne correspond à votre recherche</div>');
        }else{
          for(var i=0;i<x.length;i++){
            var clastopicp='topic';
            var liname=x[i].name;
            var licontent=x[i].content;
            if(term){
              var lis=liname.split(term);
              if(lis.length>1){
                liname='';for(var d=0;d<lis.length;d++){if(d!=(lis.length)-1){liname+=lis[d]+cus[0]+term+cus[1];}else{liname+=lis[d]};};
              };
              lis=licontent.split(term);
              if(lis.length>1){
                clastopicp='topic topicopen';
                licontent='';for(var d=0;d<lis.length;d++){if(d!=(lis.length)-1){licontent+=lis[d]+cus[0]+term+cus[1];}else{licontent+=lis[d]};};
              };
            };
            licontent=licontent.replace(/\+/g,'<br>');
            $('.conttopic').append('<div class="'+clastopicp+'" id="topic'+i+'"><div class="headtopic">'+liname+'</div><div class="bodytopic">'+licontent+'</div></div>')
          };
        };
      };
      //--------------------------------------------------------------------------
      $('.closeheadsp').click(function(){
        $('.headersp').css({'opacity':'0.0','height':'0px'});
      });
      $('.conttopic').on('click','.headtopic',function(){
        var tto=$(this).parent();
        if(tto.attr('class')=='topic'){
          $('.topic').removeClass('topicopen');
          tto.addClass('topicopen');
        }else{
          $('.topic').removeClass('topicopen');
        };
      });
      var dataSearch=[];
      var term='';
      function searchHelp(){
        if(term){
          dataSearch=dataHelp.filter(function(e){
            return (e.name.search(term)!=-1)||(e.content.search(term)!=-1);
          });
          loadHelp(dataSearch);
        }else{
          dataSearch=[];
          loadHelp(dataHelp);
        };
      };
      $('.searchhelp').keyup(function(){
        term=$(this).val().toString()||'';
        searchHelp();
      });
      // ---------------------------------------------------------
    });
  };

}