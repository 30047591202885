import { Component, OnInit } from '@angular/core';
import 'jquery';
import { ViewEncapsulation } from '@angular/core';
import { BoService } from '../services/bo.service';
declare var $: any;

@Component({
  selector: 'app-sign-alert',
  templateUrl: './sign-alert.component.html',
  styleUrls: ['./sign-alert.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SignAlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function(){
      $('.menubo,.headerbo').css({'display':'block'});
      var meno=0;
      if($('.menubo').width()==70){meno=0;}else{meno=1;};
      if(meno==0){$('.menu2bo').css({'left':'70px','opacity':'0.0'});}else{$('.menu2bo').css({'left':'220px','opacity':'0.0'});};
      $('#htitsec2').html('Déconnecté');
      $('#htitsec,#fles').css({'display':'none'});
      $('.menubs').attr('id','');
      $('.menubs1').attr('id','actif1');
      $('.headerbo').css({'border-color':'#b4aaaa4900','box-shadow':'0px 3px 18px rgb(0,0,0,0.03), 0px 2px 4px rgb(0,0,0,0.03)'});
    });
  }

}
