import { Component, OnInit } from '@angular/core';
import 'jquery';
import { ViewEncapsulation } from '@angular/core';
import { BoService } from '../services/bo.service';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-configurer-general',
  templateUrl: './configurer-general.component.html',
  styleUrls: ['./configurer-general.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigurerGeneralComponent implements OnInit {

  constructor(private boService: BoService, private router: Router, private ngZone:NgZone) { }
  
  ngOnInit() {
    let t = this;
    $(document).ready(function(){
      // --- Recuperation et création des data utiles pour jq : ------------------
      var dataList;
      var idActif='';
      var dataKeys;
      var newData={};
      var newState={};
      var dataAcs={aStaff:[]};
      var pic64='';
      var modTab=[false,false,false,false];
      //--------------------------------------------------------------------------
      // --- Initialisation globale : --------------------------------------------
      $('.menubo,.headerbo').css({'display':'block'});
      var darkMode='off';
      var contrastMode='off';
      if(JSON.parse(localStorage.getItem('infoUser')).darkMode=='on'){darkMode='on'};
      if(JSON.parse(localStorage.getItem('infoUser')).contrastMode=='on'){contrastMode='on'};
      var wiw=$(window).width();
      $('#htitsec').html('Configurer');
      $('#htitsec2').html('Général');
      $('.menubs').attr('id','');$('.menubs-3').attr('id','actif1');
      $('.secmenu2bs').attr('id','');$('.3s1').attr('id','actif2');
      if(wiw>610){
        $('#htitsec,#htitsec2,#fles').css({'display':'inline'});
      }else{
        $('#htitsec2').css({'display':'inline'});
        $('#fles').css({'display':'none'});
      };
      $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
      $(window).scroll(function(){
        var sw= $(this).scrollTop();
        if(sw>110){
          $('.headerbo').css({'border-color':'#b4aaaa00','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.05)'});
        }else{
          $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
        };
      });
      $('body').css({'overflow':'hidden'});
      $(window).scrollTop(0);
      //--------------------------------------------------------------------------
      dataList=JSON.parse(localStorage.getItem('dataList'));
      idActif=dataList._id;
      t.boService.getAllKeys().then(data => {
        dataKeys=data;
        if(dataKeys.length>0){
          dataKeys=dataKeys.sort(function(a,b){return parseInt(a.value.index)-parseInt(b.value.index)});
          for(var i=0;i<dataKeys.length;i++){
            dataKeys[i]=dataKeys[i].value;
            if(!dataKeys[i].enableList){
              dataKeys[i].enableList=[];
            };
            dataKeys[i].queue='';
          };
        };
        loadInfoList(dataList);
        loadKeys(dataKeys);
        t.boService.screenMode(darkMode,contrastMode);
        setTimeout(function(){
          $('.blocLoader').fadeOut(300);
        },300);
      }).catch(error => {
        t.boService.errorLoad();
      });
      function loadInfoList(x){
        if(x.actif==true){
          $('#inpublish').addClass('inchon').prop('checked',true);
        };
        $('#inname').val(x.name).attr('mod0',x.name).attr('mod1',x.name);
        var endDate=x.dateEndValide.split(' ');
        var endHour=endDate[1].split(':');
        endHour=endHour[0]+':'+endHour[1];
        endDate=endDate[0].split('-');
        endDate=endDate[2]+'-'+endDate[1]+'-'+endDate[0];
        $('#indate').val(endDate+'T'+endHour).attr('mod0',endDate+'T'+endHour).attr('mod1',endDate+'T'+endHour);
        var colorTag='0';
        if(x.color){
          colorTag=x.color;
        };
        $('#intag').val(colorTag).attr('mod0',colorTag).attr('mod1',colorTag);
        var description='';
        if(x.description){
          description=x.description;
        };
        $('#indescri').val(description).attr('mod0',description).attr('mod1',description);
      };
      function loadKeys(x){
        $('.listpa').html('');
        for(var i=0;i<x.length;i++){
          var kid=x[i]._id;
          var kname=x[i].name;
          var kv='';
          kv=kname.split(' ');
          if(kv.length>1){
            kv=kv[0].slice(0,1)+''+kv[1].slice(0,1);
            kv=kv.toUpperCase();
          }else{
            kv=kv[0].slice(0,1);
            kv=kv.toUpperCase();
          };
          var kcheck='';
          for(var u=0;u<x[i].enableList.length && kcheck!='checked';u++){
            if(x[i].enableList[u]==dataList._id){
              kcheck='checked';
            };
          };
          var bik='';
          if(x[i].ban==true){
            bik='bankeyPa';
          };
          $('.listpa').append('<input type="checkbox" class="inCheckPa" id="'+kid+'" index="'+i+'" mod0="'+kcheck+'" mod1="'+kcheck+'" '+kcheck+'><label class="itPa '+bik+'" for="'+kid+'"><div class="bulCheckPa"><i class="fas fa-check icCheckPa"></i>'+kv+'</div><div class="banfilterPa"><i class="fas fa-eye-slash"></i></div><div class="namePa">'+kname+'</div></label>');
        };
        $('.listpa').append('<div class="itaddPa"><div class="buladdPa"><i class="fa-solid fa-plus"></i></div><div class="nameaddPa">Créer un accès</div></div>');
      };
      //--------------------------------------------------------------------------
      // --- Fonctions / actions : -----------------------------------------------
      $('#inpublish').change(function(){
        if(checlic3==0){
          checlic3=1;
          if($(this).attr('class')=='inchon'){
            $('#inpublish').removeClass('inchon');
            newState['actif']=false;
          }else{
            $('#inpublish').addClass('inchon');
            newState['actif']=true;
          };
          $('.txtetatig').html('Sauvegarde...');
          t.boService.editList(newState).then((data) => {
            $('.txtetatig').html('État :');
            newState={};
            checlic3=0;
          }).catch(error => {
            checlic3=0;
          });
        };
      });
      $("#inname").keyup(function(){
        $(this).removeClass('signalinvide').attr('placeholder','Titre').attr('mod1',$(this).val());
        newData['name']=$(this).val();
        countMod();
      });
      $("#indate").change(function(){
        $(this).attr('mod1',$(this).val());
        if($(this).val()!=''){
          var vd=$(this).val().split('T');
          var vd1=vd[0].split('-');
          vd1=vd1[2]+'-'+vd1[1]+'-'+vd1[0];
          var vd2=vd[1]+':00';
          newData['dateEndValide']=vd1+' '+vd2;
        };
        countMod();
      });
      $("#intag").change(function(){
        newData['color']=$(this).val();
        $(this).attr('mod1',$(this).val());
        countMod();
      });
      $("#indescri").keyup(function(){
        newData['description']=$(this).val();
        $(this).attr('mod1',$(this).val());
        countMod();
      });
      $('.listpa').on('change','.inCheckPa',function(){
        var tip=$(this);
        var tipindex=tip.attr('index');
        var tabacs=dataKeys[tipindex].enableList;
        var checkidac=0;
        for(var i=0;i<tabacs.length;i++){
          if(tabacs[i]==idActif){
            tabacs.splice(i,1);
            checkidac=1;
            break;
          };
        };
        if(checkidac==0){
          tabacs.push(idActif);
        };
        dataAcs.aStaff.push({'id':dataKeys[tipindex]._id,'index':tipindex,'enableList':tabacs});
        if(tip.is(':checked')){
          tip.attr('mod1','checked');
        }else{
          tip.attr('mod1','');
        };
        countMod();
      });
      $('.listpa').on('click','.itaddPa',function(e){
        e.stopPropagation();
        t.ngZone.run(()=>t.router.navigate(['gerer-les-acces']));
      });
      function countMod(){
        var e=0;
        $('.inig').each(function(){
          if(($(this).attr('mod0')!=$(this).attr('mod1'))){
            e++;
          };
        });
        $('.inCheckPa').each(function(){
          if(($(this).attr('mod0')!=$(this).attr('mod1'))){
            e++;
          };
        });
        if(e==0){
          $('.blocmodif').html('');
          $('.btnsaveig').addClass('btnsaveigoff');
          if(darkMode=='on'){
            $('.btnsaveig').addClass('cdmcg3');
          };
        }else if(e==1){
          $('.blocmodif').html(e+' modification non-sauvegardée <span class="annulmod"><i class="fa-solid fa-xmark" style="transform:translateX(8px)translateY(1.2px);"></i></span>');
          $('.btnsaveig').removeClass('btnsaveigoff cdmcg3');
        }else if(e>1){
          $('.blocmodif').html(e+' modifications non-sauvegardées <span class="annulmod"><i class="fa-solid fa-xmark" style="transform:translateX(8px)translateY(1.2px);"></i></span>');
          $('.btnsaveig').removeClass('btnsaveigoff cdmcg3');
        };
      };
      $('.blocmodif').on('click','.annulmod',function(){
        $('.blocmodif').html('');
        $('.btnsaveig').addClass('btnsaveigoff');
        if(darkMode=='on'){
          $('.btnsaveig').addClass('cdmcg3');
        };
        $('.inig').each(function(){
          $(this).attr('mod1',$(this).attr('mod0'));
          $(this).val($(this).attr('mod0'));
        });
        $('.inCheckPa').each(function(){
          $(this).attr('mod1',$(this).attr('mod0'));
          if($(this).attr('mod0')=='checked'){
            $(this).prop('checked',true);
          }else{
            $(this).prop('checked',false);
          };
        });
        newData={};
        dataAcs={aStaff:[]};
        modTab=[false,false,false,false];
      });
      var checlic3=0;
      $('.btnsaveig').click(function(){
        if(checlic3==0){
          checlic3=1;
          var checkregle=0;
          var re=/^[ t]*$/;
          if(newData['name']){
            if(newData['name']==''||newData['name'].match(re)){
              $('#inname').addClass('signalinvide').attr('placeholder','Ce champ est requis');checkregle=1;checlic3=0;
            };
          };
          if(checkregle==0){
            // reste à faire l'enregistrement des staff
            $(this).html('Sauvegarde en cours...');
            t.boService.editList(newData).then((data) => {
              if(dataAcs.aStaff.length>0){
                t.boService.editAllKeys(dataAcs).then((data) => {
                  $('.blocmodif').html('');
                  $(this).addClass('btnsaveigoff');
                  if(darkMode=='on'){
                    $('.btnsaveig').addClass('cdmcg3');
                  };
                  $(this).html('<i class="fas fa-save"></i>&nbsp; Sauvegarder ');
                  $('.inig').each(function(){
                    $(this).attr('mod0',$(this).attr('mod1'));
                  });
                  $('.inCheckPa').each(function(){
                    $(this).attr('mod0',$(this).attr('mod1'));
                  });
                  newData={};
                  dataAcs={aStaff:[]};
                  modTab=[false,false,false,false];
                  checlic3=0;
                }).catch(error =>{
                  checlic3=0;
                });
              }else{
                $('.blocmodif').html('');
                $(this).addClass('btnsaveigoff');
                if(darkMode=='on'){
                  $('.btnsaveig').addClass('cdmcg3');
                };
                $(this).html('<i class="fas fa-save"></i>&nbsp; Sauvegarder ');
                $('.inig').each(function(){
                  $(this).attr('mod0',$(this).attr('mod1'));
                });
                $('.inCheckPa').each(function(){
                  $(this).attr('mod0',$(this).attr('mod1'));
                });
                newData={};
                modTab=[false,false,false,false];
                checlic3=0;
              };
            }).catch(error => {
              checlic3=0;
            });
          };
        };
      });
      $('.textconsig').click(function(){
        $('.popinfocode,.fdpopedit').fadeIn(500);
      });
      $('.fdpopedit').click(function(){
        $('.popinfocode,.fdpopedit').fadeOut(500);
      });
    });
  };

}
