import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgZone} from '@angular/core';
import {Router} from '@angular/router';
import 'rxjs/add/operator/toPromise';
import 'jquery';
import {stringify} from '@angular/core/src/render3/util';
import {AngularCsv} from 'angular7-csv/dist/Angular-csv';
import {ViewEncapsulation} from '@angular/core';
import {ConfigService} from './config.service';


@Injectable()
export class TicketingService {
  url: string = this.configService.apiTicketingUrl;
  encapsulation: ViewEncapsulation.None;

  constructor(public http: HttpClient, private ngZone: NgZone, private router: Router, private configService: ConfigService) {
  }

  recreateParticipant(idEvent, idTplTicket, idParticipant) {
    return this.http.get(this.url + '/sbw-events-v2/recreateParticipant', {
      headers: {
        idevent: idEvent,
        idtemplate: idTplTicket,
        idticket: idParticipant,
      }
    }).toPromise();
  }

}
