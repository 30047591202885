import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  get apiControlUrl(): string {
    return (window as any).env.apiControlUrl;
  }

  get apiTicketingUrl(): string {
    return (window as any).env.apiTicketingUrl;
  }
}
