import { Component, OnInit } from '@angular/core';
import 'jquery';
import { ViewEncapsulation } from '@angular/core';
import { BoService } from '../services/bo.service';
import { stringify } from '@angular/core/src/util';
declare var $: any;

@Component({
  selector: 'app-gerer-les-acces',
  templateUrl: './gerer-les-acces.component.html',
  styleUrls: ['./gerer-les-acces.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GererLesAccesComponent implements OnInit {

  constructor(private boService: BoService) { }

  ngOnInit() {
    let t =this;
    $(document).ready(function(){
      // --- Recuperation et création des data utiles pour jq : ------------------
      var dataKeys;
      var dataAllLists;
      var getid='';
      var dataIndex={aStaff:[]};
      var getindex=0;
      var maxitem=200;
      var maxScroll = 0;
      var cip=0;
      var passcrypt='';
      var dataSearch=[];
      var cus=['<span style="color:#ff8239;background-color:#ff823930">','</span>'];
      //--------------------------------------------------------------------------
      //--- Initialisation globale : ---------------------------------------------
      $('.menubo,.headerbo').css({'display':'block'});
      var darkMode='off';
      var contrastMode='off';
      var infoUser=JSON.parse(localStorage.getItem('infoUser'));
      if(infoUser.darkMode=='on'){darkMode='on'};
      if(infoUser.contrastMode=='on'){contrastMode='on'};
      var codeOrga='XXXXX';
      if(infoUser.codeOrganisateur){
        codeOrga=infoUser.codeOrganisateur;
      };
      var wiw=$(window).width();
      $('#htitsec').html('Mon équipe');
      $('#htitsec2').html('Gérer les accès');
      $('.menubs').attr('id','');$('.menubs-4').attr('id','actif1');
      $('.secmenu2bs').attr('id','');$('.4s1').attr('id','actif2');
      if(wiw>610){
        $('#htitsec,#htitsec2,#fles').css({'display':'inline'});
      }else{
        $('#htitsec2').css({'display':'inline'});
        $('#fles').css({'display':'none'});
      };
      $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
      $(window).scroll(function(){
        var sw= $(this).scrollTop();
        if(sw>120){
          $('.headerbo').css({'border-color':'#b4aaaa00','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.05)'});
        }else{
          $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
        };
      });
      $(window).scrollTop(0);
      $('body').css({'overflow':'hidden'});
      //--------------------------------------------------------------------------
      // --- Initialisation contenu : --------------------------------------------
      $('.valCode,.valCodePop').html(codeOrga);
      dataAllLists=JSON.parse(localStorage.getItem('dataAllLists'));
      var dll=dataAllLists.length;
      t.boService.getAllKeys().then(data => {
        dataKeys=data
        if(dataKeys.length>0){
          dataKeys=dataKeys.sort(function(a,b){return parseInt(a.value.index)-parseInt(b.value.index)});
          for(var i=0;i<dataKeys.length;i++){
            dataKeys[i]=dataKeys[i].value;
            if(!dataKeys[i].enableList){
              dataKeys[i].enableList=[];
            };
            dataKeys[i].queue='';
            if(dataKeys[i].name==undefined){
              dataKeys[i].name='Accès sans nom';
            };
            if(dataKeys[i].modeout==undefined){
              dataKeys[i].modeout=false;
            };
            if(dataKeys[i].definitiveout==undefined){
              dataKeys[i].definitiveout=true;
            };
            dataIndex.aStaff.push({'id':dataKeys[i]._id,'index':dataKeys[i].index});
          };
        }else{
          $('.txtnokey').fadeIn(0);
        };
        loadKeys(dataKeys);
        //loadList(dataAllLists);
        maxScroll = (($('.contitemga').height())-($('.contga').height()))-1;
        t.boService.screenMode(darkMode,contrastMode);
        $('.blocLoader').fadeOut(300);
      }).catch(error => {
        t.boService.errorLoad();
      });
      function loadKeys(x){
        $('.contitemga').html('');
        if(x.length>0){
          $('.txtnokey').fadeOut(0);
          for(var i=0;i<x.length;i++){
            var lvk='';
            lvk=x[i].name.split(' ');
            if(lvk.length>1){
              lvk=lvk[0].slice(0,1)+''+lvk[1].slice(0,1);
              lvk=lvk.toUpperCase();
            }else{
              lvk=lvk[0].slice(0,1);
              lvk=lvk.toUpperCase();
            };
            var liname=x[i].name;
            if(term){
              liname=highlight(liname,term);
            };
            var linblist=x[i].enableList.length;
            var bik='';
            if(x[i].ban==true){
              bik='bankey';
            };
            $('.contitemga').append('<div class="itemK '+bik+' index-'+x[i].index+'" id="'+x[i]._id+'"><div class="hK"><div class="menuK"><i class="fas fa-clone imK imK1"></i><i class="fas fa-hand-paper imK imK2"></i><i class="fas fa-trash imK imK3"></i></div><div class="bulhK"><div class="totnblist"> / '+dll+'</div><div class="nblist">'+linblist+'</div></div></div><div class="contvK"><div class="circleVk"></div><div class="vK">'+lvk+'</div><div class="banfilter"><i class="fas fa-eye-slash"></i></div></div><div class="nameK">'+liname+'</div></div>');
          };
        }else{
          if(term){
            $('.txtnokey').fadeOut(0);
          }else{
            $('.txtnokey').fadeIn(0);
          };
        };
      };
      function highlight(a,b){
        var splitMod=0;
        var termtxt='';
        var lis=a.split(b);
        if(lis.length==1){lis=a.split(b.toUpperCase());splitMod=1;};
        if(lis.length==1){lis=a.split(b.substr(0,1).toUpperCase()+b.substr(1));splitMod=2;};
        if(lis.length==1){lis=a.split(b.substr(0,1)+b.substr(1).toLowerCase());splitMod=3;};
        if(lis.length==1){lis=a.split(b.toLowerCase());splitMod=4;};
        if(lis.length>1){
          a='';
          for(var d=0;d<lis.length;d++){
            if(d!=(lis.length)-1){
              if(splitMod==1){termtxt=b.toUpperCase();
              }else if(splitMod==2){termtxt=b.substr(0,1).toUpperCase()+b.substr(1);
              }else if(splitMod==3){termtxt=b.substr(0,1)+b.substr(1).toLowerCase();
              }else if(splitMod==4){termtxt=b.toLowerCase();
              }else{termtxt=b;};
              a+=lis[d]+cus[0]+termtxt+cus[1];
            }else{
              a+=lis[d];
            };
          };
        };
        return a;
      };
      /* function loadList(x){
        for(var i=0;i<x.length;i++){
          var tplLK=x[i].value.tpl.length;
          var txttar='tarifs';
          if(tplLK<2){txttar='tarif';};
          $('.conflistK').append('<div class="liLK" mod0="" mod1=""><div class="contCheckLK"><input type="checkbox" class="inCheckLK" id="inCheckLK'+x[i].id+'"><label class="labCheckLK" for="inCheckLK'+x[i].id+'"><i class="fas fa-check icCheckLK"></i></label></div><div class="nameLK">'+x[i].value.name+'</div><div class="tplLK">'+tplLK+' '+txttar+'</div></div>');
        };
      }; */
      //--------------------------------------------------------------------------
      // --- Fonctions / actions : -----------------------------------------------
      var mx=0;
      var my=0;
      /* $('.contCode').on('mouseenter',function(){
        $('.popInfoCode').css({'opacity':1});
      }); */
      $('.contCode').on('mousemove touchmove',function(e){
        if(e.type=='mousemove'){
          mx=(e.pageX);
          my=(e.pageY);
        }else if(e.type=='touchmove'){
          mx=(e.originalEvent.touches[0].pageX);
          my=(e.originalEvent.touches[0].pageY);
        };
        $('.popInfoCode').css({'opacity':1,'left':mx+5, 'top': my+15});
      });
      $('.contCode').on('mouseout',function(){
        $('.popInfoCode').css({'opacity':0});
      });
      var cts=0;
      var s=0
      var v=0;
      var v1=0;
      var v2=0;
      var bb=0;
      var gg=0;
      var c=0;
      var hcs=0;
      var mis=0;
      var tis=0;
      var ois=0;
      var mcs=0;
      $('.contga').scroll(function(){
        cts=$(this).scrollTop();
        if(cts<0){
          cts=0;
        }else if(cts>maxScroll){
          cts=maxScroll;
        };
        if(s>270 || bb!=0){
          if(s>cts){
            if(bb==0 || bb==2){
              bb=1;
              v=cts;
              v1=gg;
            };
            s=cts;
            if(gg<=0){
              gg=0;
            }else{
              if(v1>270){
                gg=270-(v-cts);
              }else{
                gg=v1-(v-cts);
              };
            };
          }else{
            if(bb==1){
              bb=2;
              v=cts;
              v2=gg;
            };
            s=cts;
            gg=(cts-v)+v2;
          };
        }else{
          s=cts;
          gg=s;
        };
        c=gg*0.26;
        hcs=70-c;
        mis=16-(c*0.24);
        ois=1-(c*0.019);
        tis=1-(c*0.009);
        mcs=-40+(c*0.29);
        $('.contsearchResp').css({'height':hcs});
        $('.searchRespGa').css({'margin-top':mis,'opacity':ois});
        $('.searchicResp').css({'margin-top':mcs,'transform':'scale('+tis+')','opacity':ois});
        $('.cleanSearchResp').css({'margin-top':mcs,'transform':'scale('+tis+')'});
        if(term){
          $('.cleanSearchResp').css({'opacity':ois});
        };
        if(gg>=270){
          $('.contsearchResp').css({'margin-top':'69px'});
        }else{
          $('.contsearchResp').css({'margin-top':'70px'});
        };
      });
      $('.closeheadInfoGa').click(function(){
        $('.headerInfoGa').css({'opacity':'0.0','height':'0px'});
      });
      var r='r';
      var g=0;
      var c=0;
      if(wiw<=610){
         $('.bloCont').click(function(){
            $('.imK').css({'pointer-events':'none'});
         });
        setTimeout(function(){
          $('.imK').css({'pointer-events':'none'});
        },1000);
        $('.contitemga').on('click','.menuK',function(e){
          e.stopPropagation();
          $('.imK').css({'pointer-events':'none'});
          var tim=$(this).children();
          setTimeout(function(){
            tim.css({'pointer-events':'visible'});
          },300);
        });
      };
      $('.btnaddKey').click(function(){
        $('.txtnoterm').fadeOut(0);
        $('.searchga').val('');
        term='';
        searchitemK();
        if(dataKeys.length>=maxitem){
          $('.popmax,.fdpopedit').fadeIn(500);
        }else{
          $('.savestick').css({'right':'0px'});
          if(dataKeys.length==0){
            $('.txtnokey').fadeOut(0);
          };
          var newData={
            name:'Accès sans nom',
            index:0,
            other: {
              'index': 0,
              'passlength': 6
            },
            enableList: [],
            identifiant:'Accès'+dataKeys.length+1+'',
            password:'000000',
            codeOrganisateur: codeOrga,
            modeout:false,
            definitiveout:true,
            ban:false
          };
          if(c==0){g=0};
          r='r'+g;
          g++;
          c++;
          $('.contitemga').prepend('<div class="itemK index-r" id="'+r+'"><div class="hK"><div class="menuK"><i class="fas fa-clone imK imK1"></i><i class="fas fa-hand-paper imK imK2"></i><i class="fas fa-trash imK imK3"></i></div><div class="bulhK"><div class="totnblist"> / '+dll+'</div><div class="nblist">0</div></div></div><div class="contvK"><div class="circleVk"></div><div class="vK"></div></div><div class="nameK">Chargement ...</div></div>');
          newData['queue']=r;
          dataKeys.splice(0,0,newData);
          maxScroll = (($('.contitemga').height())-($('.contga').height()))-1;
          for(var i=0;i<dataKeys.length;i++){dataKeys[i].index=i;};
          var newindex=0;
          $('.itemK').each(function(){
            $(this).attr('class','itemK index-'+newindex+'');
            newindex++;
          });
          t.boService.createKey(newData).then(data => {
            var idcurrent=data['id'];
            c--;
            var s=0;
            for(var i=0;i<g;i++){
              var fi = dataKeys.findIndex(y => y.queue === 'r'+i+'');
              dataKeys[fi]['_id']=idcurrent;
              dataKeys[fi].queue='';
              if($('#r'+i+'').length!=0&&s==0){
                s=1;
                $('#r'+i+'').find('.vK').html('AS');
                $('#r'+i+'').find('.nameK').html('Accès sans nom');
                $('#r'+i+'').attr('id',idcurrent).css({'opacity':'1.0'});
              };
            };
            dataIndex.aStaff.splice(0,0,{'id':idcurrent,'index':0});
            for(var i=0;i<dataIndex.aStaff.length;i++){dataIndex.aStaff[i].index=i;};
            t.boService.editAllKeys(dataIndex).then(data => {
              $('.savestick').css({'right':'-260px'});
            }).catch(error => {
              //erreur index
            });
          })
          .catch(error => {
            // virer pos lorsque il y a erreur
          });
        };
      });
      $('.contitemga').on('click','.imK1', function(event){
        event.stopPropagation();
        var gt=$(this).parent().parent().parent();
        if(gt.attr('id').length>5){
          if(dataKeys.length>=maxitem){
            $('.popmax,.fdpopedit').fadeIn(500);
          }else{
            $('.savestick').css({'right':'0px'});
            getindex=(gt.attr('class')).split('-');
            getindex=parseInt(getindex[1]);
            var newData={
              name:'Accès sans nom',
              index:0,
              other: {
                'index': 0,
                'passlength': 6
              },
              enableList: [],
              password: '000000',
              identifiant:'Accès'+dataKeys.length+1+'',
              codeOrganisateur: codeOrga,
              modeout:false,
              definitiveout:true,
              ban:false
            };
            newData.index=dataKeys[getindex].index+1;
            newData.other.index=dataKeys[getindex].index+1;
            newData.other.passlength=dataKeys[getindex].passlength;
            newData['passlength']=dataKeys[getindex].passlength;
            newData.enableList=dataKeys[getindex].enableList;
            var newname=0;
            var newnametxt='';
            newname=parseFloat(dataKeys[getindex].name.slice(-1));
            if(isNaN(newname)){
              newnametxt=(dataKeys[getindex].name)+' 2';
            }else{
              newname++;
              newnametxt=(dataKeys[getindex].name.slice(0,-1))+' '+(newname);
            };
            newData.name=newnametxt;
            var newident=0;
            var newidenttxt='';
            newident=parseFloat(dataKeys[getindex].identifiant.slice(-1));
            if(isNaN(newident)){
              newidenttxt=(dataKeys[getindex].identifiant)+'2';
            }else{
              newident++
              newidenttxt=(dataKeys[getindex].identifiant.slice(0,-1))+(newident);
            };
            newData.identifiant=newidenttxt;
            newData.password=dataKeys[getindex].password;
            if(dataKeys[getindex].password.length>20){ // si on duplique depuis un pw crypté
              newData.other['password']=dataKeys[getindex].password;  // on envoi le pw crypté sans qu'il soit crypté
            };
            newData.modeout=dataKeys[getindex].modeout;
            newData.definitiveout=dataKeys[getindex].definitiveout;
            newData.ban=dataKeys[getindex].ban;
            var d=new Date();
            var newDate=''+(d.getDate())+'/'+(d.getMonth()+1)+'/'+(d.getFullYear())+' '+(d.getHours())+'h'+(d.getMinutes());
            if(c==0){g=0};
            r='r'+g;
            g++;
            c++;
            var lik0=newData.name.split(' ');
            var lik='';
            lik=lik0[0].slice(0,1)+''+lik0[1].slice(0,1);
            lik=lik.toUpperCase();
            if(newData.ban==true){
              gt.after('<div class="itemK index-r" id="'+r+'"><div class="hK"><div class="menuK"><i class="fas fa-clone imK imK1"></i><i class="fas fa-hand-paper imK imK2"></i><i class="fas fa-trash imK imK3"></i></div><div class="bulhK"><div class="totnblist"> / '+dll+'</div><div class="nblist">0</div></div></div><div class="contvK"><div class="circleVk"></div><div class="vK"></div></div><div class="nameK">Chargement ...</div></div>');
            }else{
              gt.after('<div class="itemK index-r" id="'+r+'"><div class="hK"><div class="menuK"><i class="fas fa-clone imK imK1"></i><i class="fas fa-hand-paper imK imK2"></i><i class="fas fa-trash imK imK3"></i></div><div class="bulhK"><div class="totnblist"> / '+dll+'</div><div class="nblist">0</div></div></div><div class="contvK"><div class="circleVk"></div><div class="vK"></div></div><div class="nameK">Chargement ...</div></div>');
            };
            newData['queue']=r;
            dataKeys.splice((getindex+1),0,newData);
            maxScroll = (($('.contitemga').height())-($('.contga').height()))-1;
            for(var i=0;i<dataKeys.length;i++){dataKeys[i]['index']=i;};
            var newindex=0;
            $('.itemK').each(function(){
              $(this).attr('class','itemK index-'+newindex+'');
              newindex++;
            });
            t.boService.createKey(newData).then(data => {
              var idcurrent=data['id'];
              c--;
              var s=0;
              var gid=0;
              for(var i=0;i<g;i++){
                var fi = dataKeys.findIndex(y => y.queue === 'r'+i+'');
                dataKeys[fi].queue='';
                if($('#r'+i+'').length!=0&&s==0){
                  s=1;
                  gid=($('#r'+i+'').attr('class')).split('-');
                  gid=parseInt(gid[1]);
                  $('#r'+i+'').find('.nameK').html(dataKeys[gid].name);
                  var lvk;
                  lvk=dataKeys[gid].name.split(' ');
                  if(lvk.length>1){
                    lvk=lvk[0].slice(0,1)+''+lvk[1].slice(0,1);
                    lvk=lvk.toUpperCase();
                  }else{
                    lvk=lvk[0].slice(0,1);
                    lvk=lvk.toUpperCase();
                  };
                  $('#r'+i+'').find('.vK').html(lvk);
                  if(dataKeys[gid].ban==true){
                    $('#r'+i+'').css({'opacity':'0.6'});
                  }else{
                    $('#r'+i+'').css({'opacity':'1.0'});
                  };
                  dataKeys[fi]['_id']=idcurrent;
                  $('#r'+i+'').attr('id',idcurrent);
                };
              };
              dataIndex.aStaff.splice(gid,0,{'id':idcurrent,'index':gid});
              for(var i=0;i<dataIndex.aStaff.length;i++){dataIndex.aStaff[i].index=i};
              t.boService.editAllKeys(dataIndex).then(data => {
                $('.savestick').css({'right':'-260px'});
              })
              .catch(error => {
                // erreur index
              });
            })
            .catch(error => {
              // virer key lorsque il y a erreur
            });
          };
        };
      });
      $('.contitemga').on('click','.imK2',function(event){
        event.stopPropagation();
        var getpar=$(this).parent().parent().parent();
        getid=getpar.attr('id');
        if(getid.length>5){
          $('.savestick').css({'right':'0px'});
          getindex=(getpar.attr('class')).split('-');
          getindex=parseInt(getindex[1]);
          var newdata={};
          newdata['name']=dataKeys[getindex].name;
          newdata['identifiant']=dataKeys[getindex].identifiant;
          newdata['password']=dataKeys[getindex].password;
          newdata['modeout']=dataKeys[getindex].modeout;
          newdata['definitiveout']=dataKeys[getindex].definitiveout;
          if(dataKeys[getindex].ban==false){
            dataKeys[getindex].ban=true;
            newdata['ban']=true;
            getpar.addClass('bankey');
            getpar.find('.banfilter').css({'opacity':1});
          }else{
            dataKeys[getindex].ban=false;
            newdata['ban']=false;
            getpar.removeClass('bankey');
            getpar.find('.banfilter').css({'opacity':0});
          };
          t.boService.editKey(getid,newdata).then(data => {
            $('.savestick').css({'right':'-260px'});
          })
          .catch(error => {
            // Annuler ban
          });
        };
      });
      $('.contitemga').on('click','.imK3',function(event){
        event.stopPropagation();
        var getpar=$(this).parent().parent().parent();
        getid=getpar.attr('id');
        if(getid.length>5){
          $('.savestick').css({'right':'0px'});
          getindex=(getpar.attr('class')).split('-');
          getindex=parseInt(getindex[1]);
          getpar.css({'transform':'scale(0.5)','opacity':'0.0'});
          dataKeys.splice(getindex,1);
          for(var i=0;i<dataKeys.length;i++){dataKeys[i].index=i;};
          setTimeout(function(){
            getpar.remove();
            var newindex=0;
            maxScroll = (($('.contitemga').height())-($('.contga').height()))-1;
            $('.itemK').each(function(){
              $(this).attr('class','itemK index-'+newindex+'');
              newindex++;
            }); 
            if(dataKeys.length==0){
              $('.txtnokey').fadeIn(0);
            };
          },200);
          t.boService.removeKey(getid).then(data => {
            dataIndex.aStaff.splice(getindex,1);
            for(var i=0;i<dataIndex.aStaff.length;i++){dataIndex.aStaff[i].index=i};
            if(dataIndex.aStaff.length>0){
              t.boService.editAllKeys(dataIndex).then(data => {
                $('.savestick').css({'right':'-260px'});
              }).catch(error => {
                // erreur index
              });
            }else{
              $('.savestick').css({'right':'-260px'});
            };
          }).catch(error => {
            // erreur suppression
          });
        };
      });
      $('.contitemga').on('click','.itemK',function(){
        getid=$(this).attr('id');
        if(getid.length>5){
          cip=0;
          inputchange=0;
          checkmod=0;
          getindex=$(this).attr('class').split('-');
          getindex=parseInt(getindex[1]);
          $('.inName').val(dataKeys[getindex].name);
          $('.inIdentifier').val(dataKeys[getindex].identifiant);
          passcrypt='';
          if(dataKeys[getindex].passlength){
            for(var i=0;i<dataKeys[getindex].passlength;i++){
              passcrypt+='0';
            };
          }else{
            passcrypt='000000';
          };
          $('.inPassword').val(passcrypt);
          if(dataKeys[getindex].modeout==true){
            $('#inmok').addClass('inchon');
            $('.labmodoutdefK').removeClass('labmodoutdefKoff');
            $('.contcheckopdefK').removeClass('contcheckopdefKoff');
          }else{
            $('#inmok').removeClass('inchon');
            $('.labmodoutdefK').addClass('labmodoutdefKoff');
            $('.contcheckopdefK').addClass('contcheckopdefKoff');
          };
          if(dataKeys[getindex].definitiveout==true){
            $('#inmodefk').addClass('inchon');
          }else{
            $('#inmodefk').removeClass('inchon');
          };
          $('.inName,.inIdentifier,.inPassword').removeClass('signalinvide')
          if(dataKeys[getindex].admin==true){
            $('.inName,.inIdentifier,.inPassword').addClass('inoff');
          }else{
            $('.inName,.inIdentifier,.inPassword').removeClass('inoff');
          };
          $('.conflistK').html('');
          var cntcheck=0;
          for(var i=0;i<dll;i++){
            var checkval='';
            for(var u=0;u<dataKeys[getindex].enableList.length;u++){
              if(dataKeys[getindex].enableList[u]==dataAllLists[i].id){
                checkval='checked';
                cntcheck++;
              };
            };
            var tplLK=dataAllLists[i].value.tpl.length;
            var txttar='tarifs';
            if(tplLK<2){txttar='tarif';};
            $('.conflistK').append('<div class="liLK" id="'+dataAllLists[i].id+'" index="'+i+'" mod0="'+checkval+'" mod1="'+checkval+'"><div class="contCheckLK"><input type="checkbox" class="inCheckLK" id="inCheckLK'+dataAllLists[i].id+'" '+checkval+'><label class="labCheckLK" for="inCheckLK'+dataAllLists[i].id+'"><i class="fas fa-check icCheckLK"></i></label></div><div class="nameLK">'+dataAllLists[i].value.name+'</div><div class="tplLK">'+tplLK+' '+txttar+'</div></div>');
          };
          $('.countLK').html(cntcheck+' / '+dll);
          $('.btnvalidedititemK').html('<i class="fas fa-save"></i> &nbsp; <span class="txtbtnvalidedititemK">Enregister</span>').addClass('btnvalidedititemKOff');
          $('.blocedit,.fdpopedit').fadeIn(500);
        };
      });
      $('.contitemga').on('mousedown','.itemK',function(){
        $(this).css({'transition-duration':'0.0s'}).addClass('itemKmove');
      });
      $('.contitemga').on('mouseup','.itemK',function(){
        $(this).css({'transition-duration':'0.3s'}).removeClass('itemKmove');
      });
      var gi1=0;
      var gi2=0;
      var gda;
      var gdax;
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      }else{
        $('.contitemga').sortable({
          appendTo: '.contitemga',
          scroll: true,
          scrollSensitivity: 35,
          distance: 5,
          zIndex: 300,
          items: ".itemK",
          start:function( event, ui ){
            gi1=ui.item.index();
            gda=dataKeys[gi1];
            gdax=dataIndex.aStaff[gi1];
          },
          stop: function( event, ui ){
            $('.savestick').css({'right':'0px'});
            gi2=ui.item.index();
            dataKeys.splice(gi1,1);
            dataKeys.splice(gi2,0,gda);
            dataIndex.aStaff.splice(gi1,1);
            dataIndex.aStaff.splice(gi2,0,gdax);
            for(var i=0;i<dataIndex.aStaff.length;i++){dataIndex.aStaff[i].index=i};
            var newindex=0;
            $('.itemK').each(function(){
              $(this).attr('class','itemK index-'+newindex+'');
              newindex++;
            });
            for(var i=0;i<dataKeys.length;i++){dataKeys[i].index=i;};
            t.boService.editAllKeys(dataIndex).then(data => {
              $('.savestick').css({'right':'-260px'});
            })
            .catch(error => {
              //erreur index
            });
          }
        });
      };
      $(".inName").change(function(){
        $(this).removeClass('signalinvide').attr('placeholder','ex : Responsable de caisse');
      });
      $(".inIdentifier").change(function(){
        $(this).removeClass('signalinvide').attr('placeholder','Identifiant unique');
      });
      $(".inPassword").focus(function(){
        if(cip==0){
          $(this).val('');
        };
      });
      $(".inPassword").focusout(function(){
        if(cip==0){
          $(this).val(passcrypt);
        };
      });
      $(".inPassword").keyup(function(){
        cip=1;
      });
      $(".inPassword").change(function(){
        $(this).removeClass('signalinvide').attr('placeholder','Mot de passe');
      });
      var inputchange=0;
      $('.inputformitemK').change(function(){
        inputchange=1;
        $('.btnvalidedititemK').html('<i class="fas fa-save"></i> &nbsp; <span class="txtbtnvalidedititemK">Enregister</span>').removeClass('btnvalidedititemKOff');
      });
      $('.inputformitemK').keyup(function(){
        inputchange=1;
        $('.btnvalidedititemK').html('<i class="fas fa-save"></i> &nbsp; <span class="txtbtnvalidedititemK">Enregister</span>').removeClass('btnvalidedititemKOff');
      });
      $('#inmok').change(function(){
        inputchange=1;
        $('.btnvalidedititemK').html('<i class="fas fa-save"></i> &nbsp; <span class="txtbtnvalidedititemK">Enregister</span>').removeClass('btnvalidedititemKOff');
        if($(this).attr('class')=='inchon'){
          $('#inmok').removeClass('inchon');
          $('.labmodoutdefK').addClass('labmodoutdefKoff');
          $('.contcheckopdefK').addClass('contcheckopdefKoff');
        }else{
          $('#inmok').addClass('inchon');
          $('.labmodoutdefK').removeClass('labmodoutdefKoff');
          $('.contcheckopdefK').removeClass('contcheckopdefKoff');
        };
      });
      $('#inmodefk').change(function(){
        inputchange=1;
        $('.btnvalidedititemK').html('<i class="fas fa-save"></i> &nbsp; <span class="txtbtnvalidedititemK">Enregister</span>').removeClass('btnvalidedititemKOff');
        if($(this).attr('class')=='inchon'){
          $('#inmodefk').removeClass('inchon');
        }else{
          $('#inmodefk').addClass('inchon');
        };
      });
      var openlist=0;
      $('.labelast').click(function(e){
        if(openlist==0){
          openlist=1;
          $('.continopK').css({'opacity':0})
          $('.contconflistK').addClass('contconflistKon');
        }else{
          openlist=0;
          $('.continopK').css({'opacity':1})
          $('.contconflistK').removeClass('contconflistKon');
        };
      });
      var checkmod=0;
      var enl=[];
      $('.conflistK').on('click','.liLK',function(){
        enl=[];
        var getparlist=$(this);
        if(getparlist.attr('mod1')=='checked'){
          getparlist.attr('mod1','');
          getparlist.find('.inCheckLK').prop('checked',false);
        }else{
          getparlist.attr('mod1','checked');
          getparlist.find('.inCheckLK').prop('checked',true)
        };
        $('.liLK').each(function(){
          if($(this).attr('mod1')=='checked'){
            enl.push($(this).attr('id'));
          };
          if($(this).attr('mod0')!=$(this).attr('mod1')){
            checkmod=1;
          };
        });
        $('.countLK').html(enl.length+' / '+dll);
        if(checkmod==1){
          $('.btnvalidedititemK').html('<i class="fas fa-save"></i> &nbsp; <span class="txtbtnvalidedititemK">Enregister</span>').removeClass('btnvalidedititemKOff');
        }else{
          if(inputchange==0){
            $('.btnvalidedititemK').html('<i class="fas fa-save"></i> &nbsp; <span class="txtbtnvalidedititemK">Enregister</span>').addClass('btnvalidedititemKOff');
          };
        };
      });
      var checlic9=0;
      $('.btnvalidedititemK').click(function(event){
        event.stopPropagation();
        if(checlic9==0){
          checlic9=1;
          var checregle=0;
          var re=/^[ t]*$/;
          if($('.inName').val()==''||$('.inName').val().match(re)){$('.inName').addClass('signalinvide').attr('placeholder','Ce champ est requis');checlic9=0;checregle=1};
          if($('.inIdentifier').val()==''||$('.inIdentifier').val().match(re)){$('.inIdentifier').addClass('signalinvide').attr('placeholder','Ce champ est requis');checlic9=0;checregle=1};
          if($('.inPassword').val()==''||$('.inPassword').val().match(re)){$('.inPassword').addClass('signalinvide').attr('placeholder','Ce champ est requis');checlic9=0;checregle=1};
          if(checregle==0){
            var newdata={
              'name': '',
              'identifiant': '',
              'modeout': false,
              'definitiveout': true
            };
            $('.savestick').css({'right':'0px'});
            $('.btnvalidedititemK').html('Enregistrement en cours...');
            dataKeys[getindex].name=$('.inName').val();
            newdata.name=$('.inName').val();
            dataKeys[getindex].identifiant=$('.inIdentifier').val();
            newdata.identifiant=$('.inIdentifier').val();
            if(cip==1){
              dataKeys[getindex].password=$('.inPassword').val();
              dataKeys[getindex].passlength=$('.inPassword').val().length;
              newdata['password']=$('.inPassword').val();
              newdata['passlength']=$('.inPassword').val().length;
            }else{
              newdata['password']=dataKeys[getindex].password;
            };
            if($('#inmok').attr('class')=='inchon'){
              dataKeys[getindex].modeout=true;
              newdata.modeout=true;
            }else{
              dataKeys[getindex].modeout=false;
              newdata.modeout=false;
            };
            if($('#inmodefk').attr('class')=='inchon'){
              dataKeys[getindex].definitiveout=true;
              newdata.definitiveout=true;
            }else{
              dataKeys[getindex].definitiveout=false;
              newdata.definitiveout=false;
            };
            t.boService.editKey(getid,newdata).then(data => {
              var lvk=dataKeys[getindex].name.split(' ');
              if(lvk.length>1){
                lvk=lvk[0].slice(0,1)+''+lvk[1].slice(0,1);
                lvk=lvk.toUpperCase();
              }else{
                lvk=lvk[0].slice(0,1);
                lvk=lvk.toUpperCase();
              };
              $('.index-'+getindex+'').children('.nameK').html(dataKeys[getindex].name);
              $('.index-'+getindex+'').find('.vK').html(lvk);
              if(checkmod==1){
                dataKeys[getindex].enableList=enl;
                t.boService.editKeyList(getid,{'enableList':enl}).then(data => {
                  $('.index-'+getindex+'').find('.nblist').html(enl.length);
                  $('.savestick').css({'right':'-260px'});
                  $('.btnvalidedititemK').html('Enregistré &nbsp;<i class="far fa-check"></i>');
                  $('.blocedit,.fdpopedit').fadeOut(500);
                  openlist=0;
                  $('.continopK').css({'opacity':1})
                  $('.contconflistK').removeClass('contconflistKon');
                checlic9=0;
                }).catch(error => {
                  // prévenir lorsqu'il y a erreur
                  checlic9=0;
                });
              }else{
                $('.savestick').css({'right':'-260px'});
                $('.btnvalidedititemK').html('Enregistré &nbsp;<i class="far fa-check"></i>');
                $('.blocedit,.fdpopedit').fadeOut(500);
                openlist=0;
                $('.continopK').css({'opacity':1})
                $('.contconflistK').removeClass('contconflistKon');
                checlic9=0;
              };
            }).catch(error => {
              // prévenir lorsqu'il y a erreur
              checlic9=0;
            });
          };
        };
      });
      $('.fdpopedit,.closepopitemK').click(function(){
        if(checlic9==0){
          $('.blocedit,.fdpopedit').fadeOut(500);
          openlist=0;
          $('.continopK').css({'opacity':1})
          $('.contconflistK').removeClass('contconflistKon');
        };
      });
      var term='';
      function searchitemK(){
        if(term){
          $('.cleanSearch,.cleanSearchResp').css({'opacity':'1','pointer-events':'visible'});
          if(dataKeys.length>0){
            dataSearch=dataKeys.filter(function(e){
              return (e.name.search(term)!=-1)
              ||(e.name.search(term.toUpperCase())!=-1)
              ||(e.name.search(term.substr(0,1)+term.substr(1).toLowerCase())!=-1)
              ||(e.name.search(term.substr(0,1).toUpperCase()+term.substr(1))!=-1)
              ||(e.name.search(term.toLowerCase())!=-1);
            });
            if(dataSearch.length<=0){
              $('.txtnoterm').fadeIn(0);
            }else{
              $('.txtnoterm').fadeOut(0);
            };
            loadKeys(dataSearch);
          };
        }else{
          $('.txtnoterm').fadeOut(0);
          $('.cleanSearch,.cleanSearchResp').css({'opacity':'0','pointer-events':'none'});
          dataSearch=[];
          loadKeys(dataKeys);
        };
      };
      $('.searchga').keyup(function(){
        term=$(this).val().toString()||'';
        searchitemK();
      });
      $('.cleanSearch').click(function(){
        $('.searchga').val('');
        term='';
         $('.txtnoterm').fadeOut(0);
        searchitemK();
      });
      $('.searchRespGa').keyup(function(){
        term=$(this).val().toString()||'';
        searchitemK();
      });
      $('.cleanSearchResp').click(function(){
        $('.searchRespGa').val('');
        term='';
        $('.txtnoterm').fadeOut(0);
        searchitemK();
      });
      $('.btnpopmax').click(function(){
        $('.popmax,.fdpopedit').fadeOut(500);
      });
    });
  };
}