import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable} from 'rxjs';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    var gi=JSON.parse(localStorage.getItem('infoUser'));
    var dkm='';
    if(gi!=undefined||gi!=null){
      dkm=gi.darkMode;
    };
    if(dkm==undefined||dkm==null||dkm==''){dkm='off'};
    if(JSON.parse(localStorage.getItem('authUser'))==true){
      if(dkm=='on'){
        $('.blocLoader').addClass('blocLoaderDark');
      }else{
        $('.blocLoader').removeClass('blocLoaderDark');
      };
      $('.blocLoader').fadeIn(0);
      $('.messError').fadeOut(0);
      return true;
    }else{
      this.router.navigate(['deconnecte']);
      $('.messError').fadeOut(0);
      return false;
    };
  }
  
}