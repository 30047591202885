import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { ConfigService } from './config.service';

@Injectable()

export class AuthService {
  url: string = this.configService.apiControlUrl + "/api";
  idUser;
  dataUser;
  auth;
  constructor(public http: HttpClient, private configService: ConfigService) { }
  signIn(mail,password) {
    let t=this;
    const headers = new HttpHeaders()
    .set("Content-Type", 'application/json')
    .set("email", mail)
    .set("password", password);
    const options='';
    return new Promise((resolve, reject) => {
      this.http.post('' + t.url + '/user/login', options, {headers})
      .toPromise()
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
    });
  };
  /* signOut(token) {
    const headers = new HttpHeaders()
    .set("Content-Type", 'application/json')
    .set("authorization", token);
    return new Promise((resolve, reject) => {
      this.http.get('http://'+this.ip+'/customer/api/logout',{headers})
      .toPromise()
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
    });
  }; */
  /* createAccount(mail,password) {
    const headers = new HttpHeaders()
    .set("email", mail)
    .set("password", password);
    const options='';
    return new Promise((resolve, reject) => {
      this.http.put('http://'+this.ip+'/customer/api/createAccount',options,{headers})
      .toPromise()
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
    });
  }; */
  /* passwordLost(mail) {
    const headers = new HttpHeaders()
    .set("Content-Type", 'application/json')
    .set("email", mail)
    return new Promise((resolve, reject) => {
      this.http.post('http://'+this.ip+'/customer/api/passwordLost',{headers})
      .toPromise()
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
    });
  }; */

  // ENV : -----------------------------------
  //192.168.1.13 > HOME
  //192.168.1.1 > WILOUT BOX
  //192.168.3.52 > WILOUT BORNE
  // -----------------------------------------

  // BASE ET SERVICES : ----------------------
  // 2.15.30.48
  // 109.210.122.79:29080 OU : 109.210.123.50 > SYLVAIN HOME
  // 192.168.3.45:8080 > SYLVAIN WILOUT PRIVÉ quand je suis au bureau

  // 192.168.1.30:8080 > SYLVAIN WILOUT PUBLIC quand je suis au bureau <<

  // 93.26.210.172:28080 > SYLVAIN WILOUT PUBLIC quand moi ext
  // -----------------------------------------

  // ACCÈS : ---------------------------------
  //raphael.coulmeau@wilout.com > ID
  //12345678 > MDP
  //sylvain.borgogno@wilout.com > ID
  //louise06 > MDP
  // -----------------------------------------

}
