import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartistModule } from 'ng-chartist';
import { TableauDeBordComponent } from './tableau-de-bord/tableau-de-bord.component';
import { BoService } from './services/bo.service';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth.guard.service';
import { ConfigService } from './services/config.service';
import { SignAlertComponent } from './sign-alert/sign-alert.component';
import { ListeDesParticipantsComponent } from './liste-des-participants/liste-des-participants.component';
import { LoginComponent } from './login/login.component';
import { NouveauCompteComponent } from './nouveau-compte/nouveau-compte.component';
import { MesInformationsComponent } from './mes-informations/mes-informations.component';
import { SupportComponent } from './support/support.component';
import { ParametresComponent } from './parametres/parametres.component';
import { ConfigurerGeneralComponent } from './configurer-general/configurer-general.component';
import { GererLesAccesComponent } from './gerer-les-acces/gerer-les-acces.component';
import { AideComponent } from './aide/aide.component';
import { ConfigurerMaListeComponent } from './configurer-ma-liste/configurer-ma-liste.component';
import { RedirectComponent } from './redirect/redirect.component';
import {TicketingService} from "./services/ticketing.service";


@NgModule({
  declarations: [
    AppComponent,
    TableauDeBordComponent,
    SignAlertComponent,
    ListeDesParticipantsComponent,
    LoginComponent,
    NouveauCompteComponent,
    MesInformationsComponent,
    SupportComponent,
    GererLesAccesComponent,
    ParametresComponent,
    ConfigurerGeneralComponent,
    ConfigurerMaListeComponent,
    AideComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ChartistModule
  ],
  providers: [BoService, AuthService, AuthGuardService, ConfigService, TicketingService],
  bootstrap: [AppComponent]
})

export class AppModule { }
