import { Component, OnInit } from '@angular/core';
import 'jquery';
import { ViewEncapsulation } from '@angular/core';
import { BoService } from '../services/bo.service';
declare var $: any;

@Component({
  selector: 'app-mes-informations',
  templateUrl: './mes-informations.component.html',
  styleUrls: ['./mes-informations.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MesInformationsComponent implements OnInit {

  constructor(private boService: BoService) { }

  ngOnInit() {
    let t = this;
    $(document).ready(function(){
      // --- Initialisation globale : --------------------------------------------
      $('.menubo,.headerbo').css({'display':'block'});
      var darkMode='off';
      var contrastMode='off';
      if(JSON.parse(localStorage.getItem('infoUser')).darkMode=='on'){darkMode='on'};
      if(JSON.parse(localStorage.getItem('infoUser')).contrastMode=='on'){contrastMode='on'};
      var wiw=$(window).width();
      $('#htitsec').html('Mon compte');
      $('#htitsec2').html('Mes informations');
      $('.menubs').attr('id','');$('.menubs-5').attr('id','actif1');
      $('.secmenu2bs').attr('id','');$('.5s1').attr('id','actif2');
      if(wiw>610){
        $('#htitsec,#htitsec2,#fles').css({'display':'inline'});
      }else{
        $('#htitsec2').css({'display':'inline'});
        $('#fles').css({'display':'none'});
      };
      $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
      $(window).scroll(function(){
        var sw= $(this).scrollTop();
        if(sw>110){
          $('.headerbo').css({'border-color':'#b4aaaa00','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.05)'});
        }else{
          $('.headerbo').css({'border-color':'#b4aaaa49','box-shadow':'0 6px 10px -1px rgba(0,0,0,0.00)'});
        };
      });
      $('body').css({'overflow':'hidden'});
      $(window).scrollTop(0);
      //--------------------------------------------------------------------------
      // --- Recuperation et création des data utiles pour jq : ------------------
      var infoUser0={};
      var infoUser={};
      var modTab=[false,false,false,false,false,false,false,false,false,false,false,false];
      infoUser0=JSON.parse(localStorage.getItem('infoUser'));
      loadUser(infoUser0);
      t.boService.screenMode(darkMode,contrastMode);
      $('.blocLoader').fadeOut(300);
      /* t.boService.getInfoUser().then(data => {
        loadUser(data);
        $('.blocLoader').fadeOut(300);
      }).catch(error => {
        t.boService.errorLoad();
      }); */
      function loadUser(x){
        if(x.firstname!=undefined){$('#infirstname').val(x.firstname);};
        if(x.lastname!=undefined){$('#inlastname').val(x.lastname);};
        if(x.email!=undefined){$('#inmail').val(x.email);};
        if(x.phonenumber!=undefined){$('#intel').val(x.phonenumber);};
        if(x.organisationName!=undefined){$('#inorganame').val(x.organisationName);};
        if(x.companyName!=undefined){$('#informejur').val(x.companyName);};
        if(x.address!=undefined){$('#inadress').val(x.address);};
        if(x.tvaIntracommunautaire!=undefined){$('#intva').val(x.tvaIntracommunautaire);};
        if(x.licence!=undefined){$('#inlicence').val(x.licence);};
        if(x.ownerBank!=undefined){$('#inownerbank').val(x.ownerBank);};
        if(x.iban!=undefined){$('#iniban').val(x.iban);};
        if(x.bic!=undefined){$('#inbic').val(x.bic);};
      };
      //--------------------------------------------------------------------------
      // --- Fonctions / actions : -----------------------------------------------
      $('#infirstname').keyup(function(){
        var valin=$(this).val();
        infoUser0['firstname']=valin;
        infoUser['firstname']=valin;
        modTab[0]=true;
        countMod();
      });
      $('#inlastname').keyup(function(){
        var valin=$(this).val();
        infoUser0['lastname']=valin;
        infoUser['lastname']=valin;
        modTab[1]=true;
        countMod();
      });
      $('#inmail').keyup(function(){
        var valin=$(this).val();
        infoUser0['email']=valin;
        infoUser['email']=valin;
        modTab[2]=true;
        countMod();
      });
      $('#intel').keyup(function(){
        var valin=$(this).val();
        infoUser0['phonenumber']=valin;
        infoUser['phonenumber']=valin;
        modTab[3]=true;
        countMod();
      });
      $('#inorganame').change(function(){
        var valin=$(this).val();
        infoUser0['organisationName']=valin;
        infoUser['organisationName']=valin;
        modTab[4]=true;
        countMod();
      });
      $('#informejur').change(function(){
        var valin=$(this).val();
        infoUser0['companyName']=valin;
        infoUser['companyName']=valin;
        modTab[5]=true;
        countMod();
      });
      $('#inadress').keyup(function(){
        var valin=$(this).val();
        infoUser0['address']=valin;
        infoUser['address']=valin;
        modTab[6]=true;
        countMod();
      });
      $('#intva').keyup(function(){
        var valin=$(this).val();
        infoUser0['tvaIntracommunautaire']=valin;
        infoUser['tvaIntracommunautaire']=valin;
        modTab[7]=true;
        countMod();
      });
      $('#inlicence').keyup(function(){
        var valin=$(this).val();
        infoUser0['licence']=valin;
        infoUser['licence']=valin;
        modTab[8]=true;
        countMod();
      });
      $('#inownerbank').keyup(function(){
        var valin=$(this).val();
        infoUser0['ownerBank']=valin;
        infoUser['ownerBank']=valin;
        modTab[9]=true;
        countMod();
      });
      $('#iniban').keyup(function(){
        var valin=$(this).val();
        infoUser0['iban']=valin;
        infoUser['iban']=valin;
        modTab[10]=true;
        countMod();
      });
      $('#inbic').keyup(function(){
        var valin=$(this).val();
        infoUser0['bic']=valin;
        infoUser['bic']=valin;
        modTab[11]=true;
        countMod();
      });
      function countMod(){
        var e=0;
        for(var i=0;i<modTab.length;i++){
          if(modTab[i]==true){
            e++;
          };
        };
        if(e==0){
          $('.blocmodifuser').html('');
        }else if(e==1){
          $('.blocmodifuser').html(e+' modification non-sauvegardée')
        }else if(e>1){
          $('.blocmodifuser').html(e+' modifications non-sauvegardées')
        };
      };
      var checlic=0;
      $('.btnsavemi').click(function(){
        if(checlic==0){
          checlic=1;
          $(this).html('Sauvegarde en cours...');
          t.boService.editInfoUser(infoUser).then(data => {
            localStorage.setItem('infoUser',JSON.stringify(infoUser0));
            $('.blocmodifuser').html('');
            $(this).html('<i class="fas fa-save"></i>&nbsp; Sauvegarder ');
            infoUser={};
            modTab=[false,false,false,false,false,false,false,false,false,false,false,false];
            checlic=0;
          }).catch(error => {
            checlic=0;
          });
        };
      });
      // ---------------------------------------------------------
    });
  };
};